import { DownArrowIcon } from 'assets/icons';
import { BB, GRAY70, GRAY80, ButtonText2 } from 'global-styles';
import { useCloseModalClickOutside } from 'hooks/useCloseModalClickOutside';
import { useRef } from 'react';
import styled, { keyframes } from 'styled-components';

type Props =
  | {
      itemTag: 'a';
      dropdownItems: { value: any; label: string; href: string }[];
    }
  | {
      itemTag: 'button';
      dropdownItems: { value: any; label: string; onClick: () => void }[];
    };

const MainDropdown = ({ itemTag, dropdownItems }: Props) => {
  const modalRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] =
    useCloseModalClickOutside(modalRef);
  const handleToggleOpen = () =>
    setIsDropdownOpen(isDropdownOpen => !isDropdownOpen);

  return (
    <MainDropdownContainer ref={modalRef}>
      <button className="select" onClick={handleToggleOpen}>
        <span>family site</span>
        <DownArrowIcon color={BB} />
      </button>

      {isDropdownOpen && (
        <MainDropdownModalContainer>
          {dropdownItems.map(item => (
            <li key={item.value}>
              <ItemSelect
                className="item-select-btn"
                onClick={
                  itemTag === 'button'
                    ? () => {
                        item.onClick!();
                        setIsDropdownOpen(false);
                      }
                    : () => {
                        setIsDropdownOpen(false);
                      }
                }
                href={itemTag === 'a' ? item.href! : undefined}
                as={itemTag || 'button'}
                target={itemTag === 'a' ? '_blank' : undefined}
              >
                <ButtonText2>{item.label}</ButtonText2>
              </ItemSelect>
            </li>
          ))}
        </MainDropdownModalContainer>
      )}
    </MainDropdownContainer>
  );
};

export default MainDropdown;

export const openAnim = keyframes` 
  0% {
    opacity: 0;
    transform: translateY(-10%);
  } 100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MainDropdownContainer = styled.div`
  position: relative;
  margin-left: 16px;
  white-space: nowrap;
  .select {
    height: 36px;
    padding: 8px 16px;
    border-radius: 24px;
    border: 1px solid ${GRAY80};
    color: ${BB};
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.8rem;
    }
  }
`;

const MainDropdownModalContainer = styled.ul`
  position: absolute;
  top: calc(100% + 6px);
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px 0;
  animation: ${openAnim} 0.1s ease-in-out;
`;

const ItemSelect = styled.button`
  font-weight: 500;
  width: 100%;
  display: block;
  text-align: center;
  padding: 8px 0;
  color: ${GRAY70};
  &:hover {
    color: ${BB};
  }
`;
