import { GRAY50, GRAY70, GRAY80, GRAYBG } from 'global-styles';
import styled, { css } from 'styled-components';

interface BasicInputProps {
  disabled: boolean;
}

export const BasicInput = styled.input`
  background: #ffffff;
  border: 1px solid ${GRAY80};
  border-radius: 4px;
  padding: 10px 16px;
  color: ${GRAY50};
  font-family: 'Noto Sans KR', sans-serif;
  line-height: 175%;
  font-size: 16px;
  letter-spacing: -1%;
  font-weight: 400;
  @media screen and (max-width: 599px) {
    font-size: 15px;
  }
  ::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: ${GRAY70};
  }
  ${({ disabled }: BasicInputProps) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${GRAYBG};
      color: ${GRAY80};
    `};
`;
