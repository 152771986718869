import styled, { keyframes } from 'styled-components';
import aiModalImg from 'public/asset/02-section1/content1/ai-model/ai-model@2x.png';
import accelerationImg from 'public/asset/02-section1/content1/acceleration/acceleration@2x.png';
import codecImg from 'public/asset/02-section1/content1/codec/codec@2x.png';
import siliconIpImg from 'public/asset/02-section1/content1/silicon-IP/silicon-IP@2x.png';
import { useRouter } from 'next/router';
import ko from '../../locale/ko';
import en from '../../locale/en';
import Image from 'next/image';
import { ImgBackgroundWrapper } from 'components/Common/CommonWrappers';
import { BB, BBDARK, GRAY50, BodyTextS } from 'global-styles';

interface TechInfoList {
  techTitle: string;
  imgSrc: string;
}

const techInfoList: TechInfoList[] = [
  {
    techTitle: 'AI model',
    imgSrc: aiModalImg.src,
  },
  {
    techTitle: 'Video & Image Codec',
    imgSrc: codecImg.src,
  },
  {
    techTitle: 'Acceleration',
    imgSrc: accelerationImg.src,
  },
  {
    techTitle: 'Semiconductor Design',
    imgSrc: siliconIpImg.src,
  },
];

export const TechBox = ({ idx }: { idx: 0 | 1 | 2 | 3 }) => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;

  return (
    <TechBoxContainer id={idx === 0 ? 'main-content1' : undefined} tabIndex={0}>
      <span className="top-color" />
      <div className="content-wrapper front">
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '200px',
          }}
        >
          <ImgBackgroundWrapper
            isNormalUse
            style={{
              width: '200px',
              height: '200px',
              left: '50%',
              transform: 'translate3d(-50%,0,0)',
            }}
          >
            <Image
              src={techInfoList[idx].imgSrc}
              alt={t.coreTech[idx].description}
              layout="fill"
              objectFit="cover"
              quality={51}
            />
          </ImgBackgroundWrapper>
        </div>
        <div className="tech-title">{techInfoList[idx].techTitle}</div>
      </div>
      <div className="content-wrapper rear">
        <div className="tech-title">{techInfoList[idx].techTitle}</div>
        <DescriptionBodyTxt>{t.coreTech[idx].description}</DescriptionBodyTxt>
        <HightlightBodyTxt>{t.coreTech[idx].highlight}</HightlightBodyTxt>
      </div>
    </TechBoxContainer>
  );
};

const opacityKeyframes = keyframes`
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
   }
`;

const DescriptionBodyTxt = styled(BodyTextS)`
  color: ${GRAY50};
  font-size: 14px;
  margin-top: 8px;
  line-height: 24px;
`;

const HightlightBodyTxt = styled(BodyTextS)`
  color: ${BB};
  line-height: 24px;
`;

const TechBoxContainer = styled.div`
  scroll-snap-align: center;
  @media screen and (max-width: 904px) {
    &:first-child {
      margin-left: 50vw;
    }
    &:last-child {
      margin-right: 50vw;
      margin-right: 100px;
      position: relative;
    }
  }
  @media screen and (max-width: 1348px) {
    &:last-child::after {
      content: '';
      display: block;
      height: 1px;
      width: 50vw;
      right: -150%;
      position: absolute;
    }
  }
  cursor: pointer;
  flex-shrink: 0;
  flex-direction: column;
  width: 300px;
  height: 384px;
  border-radius: 8px;
  background: #fff;
  transition: all 0.9s cubic-bezier(0.1, 0.57, 0.1, 1);
  margin-top: 16px;
  position: relative;
  @media screen and (min-width: 1920px) {
    width: 288px;
  }
  .top-color {
    height: 8px;
    display: block;
    background: #2564ce;
    border-radius: 8px 8px 0 0;
  }
  .front {
    display: block;
    transition: 0.3s all;
  }
  .rear {
    transition: 0.3s all;
    display: none;
  }
  .tech-title {
    font-family: 'Kanit', sans-serif;
    font-size: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 39px;
    text-align: center;
    color: ${BBDARK};
    height: 80px;
  }
  .content-wrapper {
    padding: 32px 16px;
  }
  .front .tech-title {
    margin-top: 32px;
  }
  .rear .tech-title {
    height: 60px;
    margin-bottom: 8px;
  }
  .more-btn-wrapper {
    width: 100%;
    height: fit-content;
    bottom: 32px;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  &:hover > .content-wrapper,
  &:focus-visible > .content-wrapper {
    animation: ${opacityKeyframes} 1.2s cubic-bezier(0.1, 0.57, 0.1, 1);
  }
  &:hover,
  &:focus-visible {
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    transform: translate3d(0, -16px, 0);
    .front {
      display: none;
    }
    .rear {
      display: block;
    }
  }
`;
