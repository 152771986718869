import {
  BB,
  GRAY10,
  GRAY30,
  GRAY50,
  BodyTextM,
  BodyTextMStrong,
  H3,
  LabelText,
} from 'global-styles';
import Image from 'next/image';
import React, { ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css, keyframes } from 'styled-components';
import { gridSystemMargin } from '../Layout';
import { txtAnimation } from './BannerItems';

export interface ProductTemplateProps {
  imgPath: string;
  productName: string;
  productPhrase: string;
  productDescription: string;
  productFeatureList: string[];
  buttons: ReactElement;
  reverseLayout: boolean; // true일 경우 이미지 - 글 순서
  className: string;
}

export const ProductTemplate = ({
  imgPath,
  productName,
  productPhrase,
  productDescription,
  productFeatureList,
  buttons,
  reverseLayout,
  className,
}: ProductTemplateProps) => {
  const [targetRef, isVisible] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <ProductTemplateContainer
      reverseLayout={reverseLayout}
      isVisible={isVisible}
      ref={targetRef}
      className={className}
    >
      <div className="left-container">
        <div className="product-img-wrapper">
          <Image
            src={imgPath}
            alt={productName}
            className="product-img"
            layout="fill"
            quality={70}
            sizes="(min-width: 905px) 50vw, 95vw"
          />
        </div>
      </div>
      <div className="right-container">
        <LabelText className="label-text">
          <span>{productName}</span>
        </LabelText>
        <H3 className="heading-three">
          <span>{productPhrase}</span>
        </H3>
        <BodyTextM className="body-text-m">
          <span>{productDescription}</span>
        </BodyTextM>
        <ul className="product-feature-list">
          {productFeatureList.map(productFeature => (
            <BodyTextMStrong
              as="li"
              className="product-feature"
              key={productFeature}
            >
              <span>{productFeature}</span>
            </BodyTextMStrong>
          ))}
        </ul>
        <div className="buttons-wrapper">{buttons}</div>
      </div>
    </ProductTemplateContainer>
  );
};

interface ProductTemplateContainerProps {
  reverseLayout: boolean;
  isVisible: boolean;
}

export const opacityAppear = keyframes`
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
`;

export const translateAppear = keyframes`
  0% {
    transform: translate3d(0,300%,0);
  } 100% {
    transform: translate3d(0,0,0);
  }
`;

const ProductTemplateContainer = styled.article`
  ${gridSystemMargin};
  display: flex;
  flex-direction: ${({ reverseLayout }: ProductTemplateContainerProps) =>
    reverseLayout ? 'row' : 'row-reverse'};
  & + & {
    padding-top: 146px;
  }

  .left-container {
    width: 50%;
    .product-img-wrapper {
      width: 100%;
      position: relative !important;
      .product-img {
        object-fit: contain;
        position: relative !important;
        width: 100% !important;
        height: unset !important;
        vertical-align: top;
        opacity: 0;
        transition: all 0.4s cubic-bezier(0.1, 0.57, 0.1, 1);
        ${({ isVisible }: ProductTemplateContainerProps) =>
          isVisible &&
          css`
            animation: ${opacityAppear} 1s 0.2s cubic-bezier(0.4, 0, 0.2, 1)
              forwards;
          `};
      }
    }
    .product-img-wrapper > span {
      position: unset !important;
    }
    @media screen and (min-width: 1240px) {
      padding: ${({ reverseLayout }: ProductTemplateContainerProps) =>
        reverseLayout ? '0 54px 0 0' : '0 0 0 54px'};
      .product-img {
        max-width: 544px;
        max-height: 544px;
      }
    }
    @media screen and (max-width: 1239px) and (min-width: 905px) {
      padding: ${({ reverseLayout }: ProductTemplateContainerProps) =>
        reverseLayout ? '0 32px 0 0' : '0 0 0 32px'};
      .product-img {
        max-width: 468px;
        max-height: 468px;
      }
    }
  }
  .right-container {
    width: 50%;

    .label-text {
      padding: 16px 0;
      color: ${BB};
      overflow-y: hidden;
      span {
        display: block;
        transform: translate3d(0, 300%, 0);
        ${({ isVisible }: ProductTemplateContainerProps) =>
          isVisible &&
          css`
            animation: ${translateAppear} 0.9s ease forwards;
          `};
      }
    }
    .heading-three {
      color: ${GRAY10};
      margin-bottom: 32px;
      overflow-y: hidden;
      span {
        display: block;
        transform: translate3d(0, 300%, 0);
        ${({ isVisible }: ProductTemplateContainerProps) =>
          isVisible &&
          css`
            animation: ${translateAppear} 1.2s 0.3s cubic-bezier(0.4, 0, 0.2, 1)
              forwards;
          `};
      }
      @media screen and (max-width: 1239px) {
        margin-bottom: 16px;
      }
      @media screen and (max-width: 904px) {
        margin-bottom: 8px;
      }
    }
    .body-text-m {
      color: ${GRAY50};
      overflow-y: hidden;
      opacity: 0;
      ${({ isVisible }: ProductTemplateContainerProps) =>
        isVisible &&
        css`
          animation: ${opacityAppear} 0.9s 1s cubic-bezier(0.4, 0, 0.2, 1)
            forwards;
        `};
    }
    .product-feature-list {
      margin: 16px 0 56px 16px;
      color: ${GRAY30};
      list-style: disc;
      ${({ isVisible }: ProductTemplateContainerProps) =>
        isVisible &&
        css`
          animation: ${txtAnimation} 0.9s 1.5s cubic-bezier(0.4, 0, 0.2, 1)
            backwards;
        `};
    }
    .buttons-wrapper {
      display: flex;
      width: 328px;
      justify-content: space-between;
      ${({ isVisible }: ProductTemplateContainerProps) =>
        isVisible &&
        css`
          animation: ${txtAnimation} 0.9s 1.5s cubic-bezier(0.4, 0, 0.2, 1)
            backwards;
        `};
      & > button {
        width: 156px !important;
      }
    }

    @media screen and (max-width: 904px) {
      margin-top: 16px;
      .label-text {
        padding: 16px 0 8px;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    .right-container {
      .product-feature-list {
        margin: 16px 0 32px 16px;
      }
    }
  }
  @media screen and (max-width: 904px) {
    flex-direction: column;
    justify-content: flex-start;
    .left-container {
      padding: 0;
    }
    .left-container,
    .right-container {
      width: 100%;
      height: 100%;
    }
  }
`;
