import { useContactModal } from 'hooks/useContactModal';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import styled from 'styled-components';
import en from '../../locale/en';
import ko from '../../locale/ko';
import { ContactBtnIcon, GoToIcon, NormalArrowIcon } from 'assets/icons';
import { MainButton } from 'global-styles/MainButton';
import { BB } from 'global-styles';

const SrButtons = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;

  return (
    <>
      <ButtonsMainButton
        buttonType="default"
        tag="a"
        href="https://www.kokoon.cloud"
        target="_blank"
        icon={<GoToIcon />}
        text={t.buttonText.tryDemo}
      />
      <Link href={t.lnb.srLink}>
        <ButtonsMainButton
          buttonType="default"
          tag="a"
          text={t.buttonText.learnMore}
        />
      </Link>
    </>
  );
};

const VmafButtons = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;
  const { handleOpenContactModal } = useContactModal();

  return (
    <>
      <ButtonsMainButton
        text={t.buttonText.contactUs}
        icon={<ContactBtnIcon width="16" height="14" />}
        buttonType="default"
        onClick={handleOpenContactModal}
      />
      <Link href={t.lnb.vmafLink}>
        <ButtonsMainButton
          buttonType="default"
          tag="a"
          text={t.buttonText.learnMore}
          icon={<GoToIcon />}
        />
      </Link>
    </>
  );
};

const PQOButtons = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;
  const { handleOpenContactModal } = useContactModal();

  return (
    <>
      <ButtonsMainButton
        text={t.buttonText.contactUs}
        icon={<ContactBtnIcon width="16" height="14" />}
        buttonType="default"
        onClick={handleOpenContactModal}
      />

      <Link href={t.lnb.pqoLink}>
        <ButtonsMainButton
          buttonType="default"
          tag="a"
          text={t.buttonText.learnMore}
          icon={<GoToIcon />}
        />
      </Link>
    </>
  );
};

const AvifButtons = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;
  const { handleOpenContactModal } = useContactModal();

  return (
    <>
      <ButtonsMainButton
        text={t.buttonText.contactUs}
        icon={<ContactBtnIcon width="16" height="14" />}
        buttonType="default"
        onClick={handleOpenContactModal}
      />

      <Link href={t.lnb.avifLink}>
        <ButtonsMainButton
          buttonType="default"
          tag="a"
          icon={<NormalArrowIcon color={BB} />}
          text={t.buttonText.learnMore}
        />
      </Link>
    </>
  );
};

const Av1Buttons = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;
  const { handleOpenContactModal } = useContactModal();

  return (
    <>
      <ButtonsMainButton
        text={t.buttonText.contactUs}
        icon={<ContactBtnIcon width="16" height="14" />}
        buttonType="default"
        onClick={handleOpenContactModal}
      />

      <Link href={t.lnb.av1Link}>
        <ButtonsMainButton
          buttonType="default"
          tag="a"
          icon={<GoToIcon />}
          text={t.buttonText.learnMore}
        />
      </Link>
    </>
  );
};

export const Buttons: ReactElement[] = [
  <SrButtons key="sr" />,
  <VmafButtons key="vmaf" />,
  <PQOButtons key="pq" />,
  <AvifButtons key="avif" />,
  <Av1Buttons key="av1" />,
];

// GNB menu btn=Default

// GNB menu btn=Hover & Active

// GNB menu btn=default-c

// GNB menu btn=Hover & Active- c

const ButtonsMainButton = styled(MainButton)`
  width: 156px;
  height: 48px;
`;
