import { BodyTextS } from 'global-styles';
import { useRouter } from 'next/router';
import React from 'react';

interface Props {
  contentSectionId: string;
}

export const BypassBlock = ({ contentSectionId }: Props) => {
  const { locale } = useRouter();

  return (
    <ul className="bypass-blocks-wrapper">
      <li>
        <a className="bypass-block" href="#contact-popup-btn">
          {locale === 'en' ? (
            <BodyTextS>Go to Contact</BodyTextS>
          ) : (
            <BodyTextS>문의로 바로가기</BodyTextS>
          )}
        </a>
      </li>
      <li>
        <a className="bypass-block" href={`${contentSectionId}`}>
          {locale === 'en' ? (
            <BodyTextS>Go to Main Contents</BodyTextS>
          ) : (
            <BodyTextS>본문으로 바로가기</BodyTextS>
          )}
        </a>
      </li>
    </ul>
  );
};
