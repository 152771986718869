import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import en from '../../locale/en';
import ko from '../../locale/ko';
import { NormalArrowIcon } from 'assets/icons';
import { H2KR, SubtitleM, BB } from 'global-styles';
import Image from 'next/image';
import { MainButton } from 'global-styles/MainButton';
import { generateCssStyle, mediaQueryStyle } from 'styles/breakPoints';
import { useContactModal } from 'hooks/useContactModal';
import { opacityAppear } from 'components/Home/ProductTemplate';
import contactImg from 'public/asset/04-contact/main-contact-img@2x.png';
import { useInView } from 'react-intersection-observer';

export const option: IntersectionObserverInit = {
  rootMargin: '0px',
  threshold: 0.4,
};

export const Contact = () => {
  const { handleOpenContactModal } = useContactModal();
  const [targetRef, isVisible] = useInView({
    threshold: 0.4,
    fallbackInView: true,
  });
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;

  return (
    <ContactContainer isVisible={isVisible} ref={targetRef}>
      <Image src={contactImg.src} layout="fill" alt="" objectFit="cover" />
      <div className="inner-container">
        <H2KR className="contact-h2-kr">{t.contact.title}</H2KR>
        <SubtitleM className="contact-subtitle-m">
          {t.contact.texts[0]}
        </SubtitleM>
        <SubtitleM className="contact-subtitle-m">
          {t.contact.texts[1]}
        </SubtitleM>
        <SubtitleM className="contact-subtitle-m">
          {t.contact.texts[2]}
        </SubtitleM>
        <MainButton
          text={t.contact.buttonText}
          icon={<NormalArrowIcon color={BB} />}
          buttonType="default"
          onClick={handleOpenContactModal}
          injectInlineStyles={{ width: '328px' }}
          injectCssStyles={[
            generateCssStyle({
              height: '48px',
              'margin-top': '32px',
            }),
            mediaQueryStyle.L`
            margin-top: 56px;
            height: 56px;
          `,
          ]}
        />
      </div>
    </ContactContainer>
  );
};

interface ContactContainerProps {
  isVisible: boolean;
}

export const ContactContainer = styled.section`
  height: 640px;
  position: relative;
  @media screen and (min-width: 905px) {
    height: 800px;
  }
  .inner-container {
    opacity: 0;
    ${({ isVisible }: ContactContainerProps) =>
      isVisible &&
      css`
        animation: ${opacityAppear} 0.8s ease-in-out forwards;
      `};
    .contact-h2-kr {
      color: ${BB};
      font-weight: 700;
      margin-bottom: 32px;
    }
    .contact-subtitle-m {
      color: ${BB};
    }
    @media screen and (min-width: 1440px) {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 286px;
      padding-top: 146px;
    }
    @media screen and (min-width: 1240px) and (max-width: 1439px) {
      width: calc(100% - 192px);
      margin: 0 96px 296px;
      padding-top: 146px;
    }
    @media screen and (min-width: 905px) and (max-width: 1239px) {
      width: calc(100% - 112px);
      margin: 0 56px 223px;
      padding-top: 96px;
    }
    @media screen and (min-width: 600px) and (max-width: 904px) {
      width: calc(100% - 64px);
      margin: 0 32px 364px;
      padding-top: 56px;
    }
    @media screen and (max-width: 599px) {
      width: calc(100% - 32px);
      margin: 0 16px 336px;
      padding-top: 56px;
    }
  }
`;
