import ReactDOM from 'react-dom';
import { useRouter } from 'next/router';
import { ContactPopupContainer, PopupBackground } from './ContactPopup';
import styled from 'styled-components';
import { CloseIcon } from 'assets/icons';
import { MainButton } from 'global-styles/MainButton';
import { en, ko } from 'locale/privacyPolicy';
import { ImgBackgroundWrapper } from 'components/Common/CommonWrappers';
import Image from 'next/image';
import {
  GRAY50,
  GRAY70,
  BodyTextLStrong,
  BodyTextMStrong,
  BodyTextS,
  SubtitleM,
} from 'global-styles';
import { useInView } from 'react-intersection-observer';

interface PrivatePolicyPopup {
  handleClosePrivatePolicy: () => void;
}

export const PrivatePolicyPopup = ({
  handleClosePrivatePolicy,
}: PrivatePolicyPopup) => {
  const { locale } = useRouter();
  const [observerRef, isVisible] = useInView({
    threshold: 1,
    fallbackInView: true,
  });

  const $portal = document.querySelector('#portal');
  const articleArray = locale === 'en' ? en.articles : ko.articles;

  if (!$portal) return null;

  return ReactDOM.createPortal(
    <PopupBackground>
      <PrivatePopupContainer>
        <header>
          <BodyTextLStrong>Privacy Policy</BodyTextLStrong>
          <button
            className="close-contact-btn"
            onClick={handleClosePrivatePolicy}
          >
            <CloseIcon />
          </button>
        </header>
        <div className="mid">
          <SubtitleM className="mid-title">
            {locale === 'en'
              ? 'BLUEDOT Privacy policy'
              : '블루닷 개인정보처리방침'}
          </SubtitleM>
          <BodyTextMStrong className="mid-last-up">
            {locale === 'en'
              ? '2022.09.07 last updated'
              : '2022.09.07 마지막으로 업데이트 됨'}
          </BodyTextMStrong>
          {!isVisible && (
            <div className="pri-gradation top">
              <ImgBackgroundWrapper isNormalUse>
                <Image
                  src="/asset/04-contact/g-bottom.png"
                  layout="fill"
                  alt="bluedot"
                  className="banner-img"
                  quality={70}
                  sizes="(min-width: 906px) 90vw,
                 (min-width: 600px) 85vw,
                 (max-width: 599px) 75vw"
                />
              </ImgBackgroundWrapper>
            </div>
          )}
        </div>

        <div className="scrollable">
          <div className="policy">
            <span className="ot" ref={observerRef} />
            <BodyTextS className="mid-desc">
              {locale === 'en' ? en.midDesc : ko.midDesc}
            </BodyTextS>

            <div className="policy-area">
              {articleArray.map(art => (
                <div className="pol-box" key={art.title}>
                  <BodyTextMStrong>{art.title}</BodyTextMStrong>
                  <BodyTextS as="pre" className="pol-desc">
                    {art.desc}
                  </BodyTextS>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-bottom-area">
          <div className="pri-gradation">
            <ImgBackgroundWrapper isNormalUse>
              <Image
                src="/asset/04-contact/g-bottom.png"
                layout="fill"
                alt="bluedot"
                className="banner-img"
                quality={70}
                sizes="(min-width: 906px) 90vw,
                 (min-width: 600px) 85vw,
                 (max-width: 599px) 75vw"
              />
            </ImgBackgroundWrapper>
          </div>
          <MainButton
            onClick={handleClosePrivatePolicy}
            buttonType="default"
            text="확인"
            injectInlineStyles={{ width: '328px', height: '56px' }}
          />
        </div>
      </PrivatePopupContainer>
    </PopupBackground>,
    $portal
  );
};

const PrivatePopupContainer = styled(ContactPopupContainer)`
  position: relative;
  .ot {
    display: block;
    width: 100%;
  }
  .mid {
    position: relative;
    text-align: center;
    padding: 32px 32px 0 32px;
  }
  .pri-gradation.top {
    transform: rotate(180deg);
    top: 100px;
    left: 0;
  }
  .mid-last-up {
    margin-bottom: 16px;
    color: ${GRAY70};
  }
  .mid-desc {
    margin-bottom: 32px;
    text-align: left;
    color: ${GRAY50};
  }
  .scrollable {
    padding: 0 32px 32px;
    height: calc(100% - 186px - 88px);
    overflow-y: auto;
  }
  .policy {
    margin-top: 0px;
  }
  .modal-bottom-area {
    height: 104px;
    padding: 16px 0 32px;
  }
  .pol-box {
    color: ${GRAY50};
  }
  .pol-box + .pol-box {
    margin-top: 32px;
  }
  .pol-desc {
    color: ${GRAY70};
    white-space: pre-line;
  }
  .pri-gradation {
    top: -32px;
    width: 100%;
    height: 32px;
    position: absolute;
    object-fit: cover;
  }
  @media screen and (max-width: 599px) {
    .scrollable {
      padding: 0 16px 16px;
    }
    .policy {
      padding: 0;
    }
  }
`;
