import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { CloseIcon } from 'assets/icons';
import { BodyTextL, BodyTextLStrong, BB, GRAY30, GRAY50 } from 'global-styles';
import { MainButton } from 'global-styles/MainButton';

interface Props {
  width?: string;
  headerText: string;
  bodyComponent?: ReactNode;
  bodyText?: string;
  buttonText?: string;
  // eslint-disable-next-line no-unused-vars
  buttonFn?: (...args: any[]) => any;
  // eslint-disable-next-line no-unused-vars
  closeBtnFn: (...args: any[]) => any;
}

export const BasicPopup = ({
  width,
  headerText,
  bodyComponent,
  bodyText,
  buttonText,
  buttonFn,
  closeBtnFn,
}: Props) => {
  const { locale } = useRouter();

  return (
    <BasicPopupContainer width={width}>
      <div className="header">
        <BodyTextLStrong>{headerText}</BodyTextLStrong>
        <button className="close-btn" onClick={closeBtnFn}>
          <CloseIcon color={GRAY50} />
        </button>
      </div>

      <div className="body">
        {bodyComponent ? (
          <>{bodyComponent}</>
        ) : (
          <div className="basic-popup-body">
            <BodyTextL>{bodyText || ''}</BodyTextL>

            <MainButton
              buttonType="default"
              text={buttonText || locale === 'en' ? 'OK' : '확인'}
              onClick={() => {
                if (buttonFn) buttonFn();
              }}
              injectInlineStyles={{
                width: '156px',
                height: '48px',
                marginTop: '16px',
              }}
            />
          </div>
        )}
      </div>
    </BasicPopupContainer>
  );
};

const BasicPopupContainer = styled.div`
  width: ${({ width }: { width?: string }) => width || '328px'};
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  .header {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: ${BB};
  }
  .close-btn {
    position: absolute;
    right: 15px;
  }
  .basic-popup-body {
    color: ${GRAY30};
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
