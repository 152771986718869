import { opacityDisappear } from 'avif-comparison/src/components/AppModal';
import { opacityAppear } from 'components/Home';
import { ALLSTROKE, MENUBG } from 'global-styles';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useAnimation } from 'shared-utils';
import styled, { css } from 'styled-components';
import { Products, Submenus } from '.';
import { productMenuItems, companyMenuItems } from './menusInfo';
import SnsComponent from './SnsComponent';
import { DetailAreaTemplate } from './SubMenusDetailAreaTemplate';
import {
  CompanySubMenuAreaProductTemplate,
  SubMenuAreaProductTemplate,
} from './SubMenusMenuAreaTemplate';
import sr1sr2Img from 'public/asset/header/thumbnails/sr1sr2.jpg';
import sr3Img from 'public/asset/header/thumbnails/sr3.jpg';
import pqoImg from 'public/asset/header/thumbnails/pqo.jpg';
import av1Img from 'public/asset/header/thumbnails/av1.jpg';
import avifImg from 'public/asset/header/thumbnails/avif.jpg';
import vmafImg from 'public/asset/header/thumbnails/vmaf.jpg';

type Props = {
  submenuType: Submenus;
  setSubmenuType: Dispatch<SetStateAction<Submenus>>;
  setProduct: Dispatch<SetStateAction<Products>>;
  product: Products;
};

type SubmenuComponents = Record<
  Submenus,
  { detailArea: JSX.Element; menuList: JSX.Element }
>;

type ProductDetailObj = {
  thumbnailText: string;
  thumbnailURL: string;
};
const productDetailObj: Record<Products, ProductDetailObj> = {
  'DeepField-SR1/SR2': {
    thumbnailText: ': DeepField-SR1/SR2',
    thumbnailURL: sr1sr2Img.src,
  },
  'DeepField-SR3': {
    thumbnailText: ': DeepField-SR3',
    thumbnailURL: sr3Img.src,
  },
  'DeepField-PQO': {
    thumbnailText: ': DeepField-PQO',
    thumbnailURL: pqoImg.src,
  },
  'Pulsar-AV1': {
    thumbnailText: ': Pulsar-AV1',
    thumbnailURL: av1Img.src,
  },
  'Pulsar-AVIF': {
    thumbnailText: ': Pulsar-AVIF',
    thumbnailURL: avifImg.src,
  },
  'Pulsar-VMAF': {
    thumbnailText: ': Pulsar-VMAF',
    thumbnailURL: vmafImg.src,
  },
};

const SubMenus = ({
  submenuType,
  setSubmenuType,
  setProduct,
  product,
}: Props) => {
  const [isVisible, showAnimation] = useAnimation(submenuType !== null, 150);

  const submenuComponents: SubmenuComponents = useMemo(
    () => ({
      company: {
        detailArea: (
          <DetailAreaTemplate
            menuIntroText="Company"
            menuIntroExplainText={{
              en: 'Introduction & Information.',
              ko: '기업 소개 및 노하우를 전합니다.',
            }}
            thumbnailText=""
            thumbnailURL=""
          />
        ),
        menuList: (
          <CompanySubMenuAreaProductTemplate items={companyMenuItems} />
        ),
      },
      product: {
        detailArea: (
          <DetailAreaTemplate
            menuIntroText="Product"
            menuIntroExplainText={{
              en: 'Satisfying Viewing Experience.',
              ko: '만족도를 높이는 시청 경험.',
            }}
            thumbnailText={productDetailObj[product].thumbnailText}
            thumbnailURL={productDetailObj[product].thumbnailURL}
          />
        ),
        menuList: (
          <SubMenuAreaProductTemplate
            topText={{ left: 'DeepField', right: 'Pulsar' }}
            items={productMenuItems}
            setProduct={setProduct}
          />
        ),
      },
    }),
    [setProduct, product]
  );

  if (!isVisible && !showAnimation) return null;

  return (
    <SubMenusContainer
      showAnimation={showAnimation}
      onMouseOver={() => {
        setSubmenuType(submenuType);
      }}
      onMouseLeave={() => setSubmenuType(null)}
    >
      <div className="inner-wrapper">
        {/* left (menu description area + sns area) */}
        <div className="l-area">
          {submenuComponents[submenuType]?.detailArea || ''}
          <div className="desk-sns-wrapper">
            <SnsComponent />
          </div>
        </div>

        {/* right (menu items / scrollable area) */}
        <div className="r-area">
          {submenuComponents[submenuType]?.menuList || ''}
        </div>
      </div>
    </SubMenusContainer>
  );
};

export default SubMenus;

type SubMenusContainerProps = {
  showAnimation: boolean;
};

const SubMenusContainer = styled.div<SubMenusContainerProps>`
  position: absolute;
  z-index: 999;
  top: 100%;
  left: 0;
  width: 100%;
  height: 628px;
  max-height: 628px;
  background: ${MENUBG};
  display: flex;
  justify-content: center;
  animation: ${opacityAppear} 0.15s ease-in;
  .inner-wrapper {
    height: 100%;
    width: fit-content;
    color: #fff;
    display: flex;
  }
  .l-area {
    position: relative;
    width: 300px;
    height: 100%;
  }
  .r-area {
    width: 900px;
    border-left: 1px solid ${ALLSTROKE};
    border-right: 1px solid ${ALLSTROKE};
    height: 100%;
  }
  .desk-sns-wrapper {
    position: absolute;
    bottom: 16px;
    left: 0;
  }
  ${({ showAnimation }) =>
    showAnimation &&
    css`
      animation: ${opacityDisappear} 0.15s ease-in;
    `};

  @media screen and (max-width: 1239px) {
    .r-area {
      width: 720px;
    }
  }
  @media screen and (max-width: 1048px) {
    display: none;
  }
`;
