import { GoToIcon } from 'assets/icons';
import { Dropdown } from 'components/Global';
import { BB, GRAY80, ProductHeaderText, PRODUCTSUB } from 'global-styles';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { Submenus } from '.';

type Props = {
  isScrolled: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSubmenuType: Dispatch<SetStateAction<Submenus>>;
};

const DesktopNavList = ({ isScrolled, setSubmenuType }: Props) => {
  const { asPath } = useRouter();

  return (
    <DesktopNavListContainer isScrolled={isScrolled}>
      <ul className="nav-items desk">
        <li
          className="nav-item"
          onMouseOver={() => setSubmenuType('company')}
          onMouseLeave={() => setSubmenuType(null)}
          data-is-selected={
            asPath.toLowerCase().includes('about') ||
            asPath.toLowerCase().includes('blog') ||
            asPath.toLowerCase().includes('event')
          }
        >
          <ProductHeaderText>COMPANY</ProductHeaderText>
        </li>

        <li
          className="nav-item"
          onMouseOver={() => setSubmenuType('product')}
          onMouseLeave={() => setSubmenuType(null)}
          data-is-selected={asPath.toLowerCase().includes('product')}
        >
          <ProductHeaderText>PRODUCT</ProductHeaderText>
        </li>

        <li className="nav-item" onMouseOver={() => setSubmenuType(null)}>
          <a
            href="https://blue-dot.career.greetinghr.com/"
            target="_blank"
            rel="noreferrer"
          >
            <ProductHeaderText>CAREER</ProductHeaderText>
            <GoToIcon color={GRAY80} />
          </a>
        </li>

        <li
          className="nav-item"
          onMouseOver={() => setSubmenuType(null)}
          data-is-selected={false}
        >
          <Link href="/product/avif/#product-section">
            <a>
              <ProductHeaderText>Image Optimization</ProductHeaderText>
            </a>
          </Link>
        </li>

        <li
          className="nav-item"
          onMouseOver={() => setSubmenuType(null)}
          data-is-selected={asPath.toLowerCase().includes('cloud')}
        >
          <Link href="https://www.kokoon.cloud" target="_blank">
            <a target="_blank">
              <ProductHeaderText>Cloud</ProductHeaderText>
              <GoToIcon color="#fff" />
            </a>
          </Link>
        </li>
      </ul>

      <Dropdown isScrolled={isScrolled} isOpen handleToggleMenu={() => {}} />
    </DesktopNavListContainer>
  );
};

export default DesktopNavList;

type DesktopNavListContainerProps = {
  isScrolled: boolean;
};

const DesktopNavListContainer = styled.div<DesktopNavListContainerProps>`
  display: flex;
  justify-content: space-between;
  z-index: 9999;
  .nav-items {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    color: ${PRODUCTSUB};
  }
  .nav-item {
    transition: 0.2s all;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 100%;
    color: ${GRAY80};
  }
  .nav-item[data-is-selected='true'] {
    color: ${({ isScrolled }) => (isScrolled ? `${BB}` : '#fff')};
  }
  .nav-item + .nav-item {
    padding-left: 32px;
  }
  .nav-item svg {
    margin-left: 6px;
  }
  .nav-item svg path {
    transition: 0.2s all;
  }
  .nav-item:hover {
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }

  a,
  a:visited {
    color: inherit;
  }

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      .nav-item:hover {
        color: ${BB};

        svg {
          path {
            fill: ${BB};
          }
        }
      }

      svg {
        path {
          fill: ${PRODUCTSUB};
        }
      }
    `};

  @media screen and (max-width: 1048px) {
    display: none;
  }
`;
