import React, { useRef } from 'react';
import styled from 'styled-components';
import { useCloseModalClickOutside } from '../../hooks/useCloseModalClickOutside';
import { DownArrowIcon } from 'assets/icons';
import { useRouter } from 'next/router';
import { ButtonText2, BB, GRAY70, GRAY80 } from 'global-styles';
import { openAnim } from 'components/Common/MainDropdown';

interface Props {
  isOpen: boolean;
  isScrolled: boolean;
  handleToggleMenu: () => void;
}

export const Dropdown = ({ isOpen, isScrolled, handleToggleMenu }: Props) => {
  const modalRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] =
    useCloseModalClickOutside(modalRef);
  const { locale, push, pathname, replace, asPath } = useRouter();

  return (
    <DropDownContainer isScrolled={isScrolled} isOpen={isOpen} ref={modalRef}>
      <button
        className="select"
        aria-label={
          isOpen
            ? 'close the language change pop up'
            : 'open the language change pop up'
        }
        aria-expanded={isOpen}
        aria-controls="language-select-modal"
        onClick={() => {
          setIsDropdownOpen(isDropdownOpen => !isDropdownOpen);
        }}
      >
        <LanguageBtnText isScrolled={isScrolled} isOpen={isOpen}>
          {locale === 'en' ? <span>ENG</span> : <span>한국어</span>}
        </LanguageBtnText>
        <DownArrowIcon
          color={isOpen ? '#fff' : isScrolled ? BB : '#fff'}
          size="16"
        />
      </button>

      {isDropdownOpen && (
        <DropdownModal id="language-select-modal">
          <li>
            <button
              aria-label="choose English as display language."
              onClick={() => {
                replace(pathname, asPath, { locale: 'en', scroll: false });
                setIsDropdownOpen(false);
                if (isOpen) handleToggleMenu();
              }}
            >
              <OptionBtnText className={locale === 'en' ? 'selected' : ''}>
                ENG
              </OptionBtnText>
            </button>
          </li>
          <li>
            <button
              aria-label="choose Korean as display language."
              onClick={() => {
                push(pathname, asPath, { locale: 'ko', scroll: false });
                setIsDropdownOpen(false);
                if (isOpen) handleToggleMenu();
              }}
            >
              <OptionBtnText className={locale === 'ko' ? 'selected' : ''}>
                한국어
              </OptionBtnText>
            </button>
          </li>
        </DropdownModal>
      )}
    </DropDownContainer>
  );
};

interface DropDownContainerProps {
  isOpen: boolean;
  isScrolled: boolean;
}

export const DropDownContainer = styled.div<DropDownContainerProps>`
  position: relative;
  .select {
    height: 36px;
    background: transparent;
    padding: 8px 16px;
    border: ${({ isScrolled }) =>
      isScrolled ? `1px solid ${GRAY80}` : `1px solid #fff`};
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.8rem;
    }
  }
`;

const LanguageBtnText = styled(ButtonText2)<DropDownContainerProps>`
  width: 45px;
  color: ${({ isScrolled }) => (isScrolled ? '#888' : `#fff`)};
`;

export const OptionBtnText = styled(ButtonText2)`
  color: ${GRAY70};
`;

export const DropdownModal = styled.ul`
  position: absolute;
  top: calc(100% + 6px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  width: 100px;
  animation: ${openAnim} 0.1s ease-in-out;
  background: #ffffff;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.12);
  li {
    width: 100%;
    height: 36px;
    button {
      background: transparent;
      width: 100%;
      height: 100%;
    }
  }
  .selected {
    color: ${BB} !important;
    border-bottom: 1px solid ${BB};
  }
  @media screen and (max-width: 904px) {
    width: 100px;
  }
`;
