import * as Accordion from '@radix-ui/react-accordion';
import { ChevronVerticalIcon, GoToIcon } from 'assets/icons';
import { ALLSTROKE, MobileBtnSubText, MobileBtnText } from 'global-styles';
import { useContactModal } from 'hooks/useContactModal';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

type LinkItem = { label: string; href: string };

const companyItems: LinkItem[] = [
  { label: 'About', href: '/about' },
  { label: 'Blog', href: '/blog' },
  { label: 'Event', href: '/event' },
  { label: 'Contact', href: '/contact' },
];
const productItems: LinkItem[] = [
  { label: 'DeepField-SR1/SR2', href: '/product/sr1sr2/#product-section' },
  { label: 'DeepField-SR3', href: '/product/sr3/#product-section' },
  { label: 'DeepField-PQO', href: '/product/pqo/#product-section' },
  { label: 'Pulsar-AV1', href: '/product/av1/#product-section' },
  { label: 'Pulsar-AVIF', href: '/product/avif/#product-section' },
  { label: 'Pulsar-VMAF', href: '/product/vmaf/#product-section' },
];

type NavMobileAccordionProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const NavMobileAccordion = ({ setIsOpen }: NavMobileAccordionProps) => {
  const router = useRouter();
  const { handleOpenContactModal } = useContactModal();

  useEffect(() => {
    const handleCloseIsOpenMobileNavMenu = () => {
      setIsOpen(false);
    };

    router.events.on('routeChangeComplete', handleCloseIsOpenMobileNavMenu);

    return () =>
      router.events.off('routeChangeComplete', handleCloseIsOpenMobileNavMenu);
  }, [router, setIsOpen]);

  return (
    <Accordion.Root type="multiple">
      <StyledAccordionItem value="company">
        <StyledAccordionTrigger>
          <AccordionBtnText>COMPANY</AccordionBtnText>
          <ChevronVerticalIcon className="chev-vert" />
        </StyledAccordionTrigger>

        <StyledAccordionContent>
          <ul className="link-item-list">
            {companyItems.map(item => (
              <li className="link-item" key={item.href}>
                {item.label === 'Contact' ? (
                  <AccordionBtnSubText
                    as="button"
                    onClick={handleOpenContactModal}
                    isSelected={false}
                    style={{ color: '#ccc', padding: '0' }}
                  >
                    {item.label}
                  </AccordionBtnSubText>
                ) : (
                  <Link href={item.href}>
                    <AccordionBtnSubText
                      as="a"
                      isSelected={router.asPath
                        .toLowerCase()
                        .includes(item.href)}
                    >
                      {item.label}
                    </AccordionBtnSubText>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </StyledAccordionContent>
      </StyledAccordionItem>

      <StyledAccordionItem value="product">
        <StyledAccordionTrigger>
          <AccordionBtnText>PRODUCT</AccordionBtnText>
          <ChevronVerticalIcon className="chev-vert" />
        </StyledAccordionTrigger>

        <StyledAccordionContent>
          <ul className="link-item-list">
            {productItems.map(item => (
              <li className="link-item" key={item.href}>
                <Link href={item.href}>
                  <AccordionBtnSubText
                    as="a"
                    isSelected={router.asPath.toLowerCase().includes(item.href)}
                  >
                    {item.label}
                  </AccordionBtnSubText>
                </Link>
              </li>
            ))}
          </ul>
        </StyledAccordionContent>
      </StyledAccordionItem>

      <StyledAccordionItem value="career">
        <StyledAccordionTrigger>
          <AccordionBtnText>
            <a
              href="https://blue-dot.career.greetinghr.com/"
              target="_blank"
              rel="noreferrer"
              className="blank-a"
            >
              CAREER
              <GoToIcon color="#fff" className="goto-icon" />
            </a>
          </AccordionBtnText>
        </StyledAccordionTrigger>
      </StyledAccordionItem>

      <StyledAccordionItem value="web-interface">
        <StyledAccordionTrigger>
          <Link href="/product/avif#product-section">
            <AccordionBtnText as="a">Image Optimization</AccordionBtnText>
          </Link>
        </StyledAccordionTrigger>
      </StyledAccordionItem>

      <StyledAccordionItem value="web-interface">
        <StyledAccordionTrigger
          onClick={() => {
            window.open('https://www.kokoon.cloud');
          }}
        >
          <Link href="https://www.kokoon.cloud" target="_blank">
            <AccordionBtnText as="a" target="_blank">
              <span>Cloud</span>
              <GoToIcon color="#fff" className="goto-icon" />
            </AccordionBtnText>
          </Link>
        </StyledAccordionTrigger>
      </StyledAccordionItem>
    </Accordion.Root>
  );
};

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

const StyledAccordionItem = styled(Accordion.Item)`
  a,
  a:visited {
    color: inherit;
  }

  .link-item + .link-item {
    margin-top: 25px;
  }
`;

const StyledAccordionTrigger = styled(Accordion.Trigger)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 48px;
  border-bottom: 1px solid ${ALLSTROKE};
  color: #fff;
  & .chev-vert {
    transition: transform 0.25s;
    transform: rotate(180deg);
  }
  &[data-state='open'] .chev-vert {
    transform: rotate(0deg);
  }
  &:active {
    transform: none;
  }
  .goto-icon {
    margin-left: 10px;
  }
  .blank-a {
    display: flex;
    width: 100%;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    padding: 16px 24px;
  }
  @media screen and (max-width: 360px) {
    padding: 8px;
  }
`;

const AccordionBtnText = styled(MobileBtnText)`
  padding: 8px;
  display: flex;
  width: 100%;
  align-items: center;
`;

const AccordionBtnSubText = styled(MobileBtnSubText)<{ isSelected: boolean }>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #fff !important;
      font-weight: bold;
    `};

  &:hover {
    color: #fff;
  }
`;

const StyledAccordionContent = styled(Accordion.Content)`
  color: #ccc;
  overflow: hidden;
  &[data-state='open'] {
    border-bottom: 1px solid ${ALLSTROKE};
    animation: ${slideDown} 0.25s ease-out;
  }
  &[data-state='closed'] {
    animation: ${slideUp} 0.25s ease-out;
  }

  .link-item-list {
    padding: 24px 72px;
  }
  @media screen and (max-width: 600px) {
    .link-item-list {
      padding: 24px 48px;
    }
  }
  @media screen and (max-width: 360px) {
    .link-item-list {
      padding: 16px 24px;
    }
  }
`;
