import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useId,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { ButtonText2 } from 'global-styles';
import {
  DropdownProps,
  OptionItemProps,
  OptionListProps,
  ToggleContextValue,
  ToggleProps,
} from './types';
import { useSetOptionListRendering } from './hooks/useSetOptionListRendering';
import { useClick } from './hooks/useClick';
import { useKeydown } from './hooks/useKeydown';
import {
  ToggleContainer,
  OptionListContainer,
  OptionItemContainer,
} from './styles';
import { ChevronIcon } from 'assets/icons';

export const DropdownContext = createContext<ToggleContextValue>({
  isOpen: false,
  toggle: () => {},
  onChange: () => {},
  disabled: false,
  container: null,
  toggleRef: null,
  optionListRef: null,
  changeStyle: false,
  displayBorder: true,
});

export const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (!context) {
    throw new Error(
      'Toggle compound components cannot be rendered outside the Toogle component.'
    );
  }

  return context;
};

export const Dropdown = ({
  children,
  changeStyle = false,
  disabled = false,
  displayBorder = true,
  isPositionFixed,
  onChange,
}: PropsWithChildren<DropdownProps>) => {
  const [container, setContainer] = useState<null | HTMLDivElement>(null);
  const containerUniqueId = useId();
  const isOpen = container !== null;

  const close = () => {
    const $container = document.getElementById(containerUniqueId);
    if ($container) {
      $container.remove();
    }
    setContainer(null);
  };

  const open = () => {
    const $container = document.getElementById(
      containerUniqueId
    ) as HTMLDivElement;
    if ($container) {
      setContainer($container);
    } else {
      const $newContainer = document.createElement('div');
      $newContainer.id = containerUniqueId;
      document.body.appendChild($newContainer);
      setContainer($newContainer);
    }
  };

  const toggle = useCallback(() => {
    if (isOpen) close();
    else open();
  }, [isOpen, container]);

  const toggleRef = useRef<HTMLButtonElement>(null);
  const optionListRef = useRef<HTMLUListElement>(null);

  const providerValue: ToggleContextValue = {
    isOpen,
    disabled,
    onChange,
    toggle,
    container,
    toggleRef,
    optionListRef,
    changeStyle,
    displayBorder,
  };

  useSetOptionListRendering({
    isOpen,
    container,
    toggleRef,
    optionListRef,
    isPositionFixed,
  }); // optionList 위치 조절 및 렌더링 관리
  useClick({ toggleRef, optionListRef, close, toggle, onChange }); // click 이벤트 관리 hook
  useKeydown({ toggleRef, optionListRef, close, toggle, onChange }); // keyboard 이벤트 관리 hook

  return (
    <DropdownContext.Provider value={providerValue}>
      {children}
    </DropdownContext.Provider>
  );
};

// 하위 컴포넌트들을 Dropdown 컴포넌트의 property로 사용 -> Dropdown만 import 하면 나머지 컴포넌트들도 사용가능 + 네임스페이스 분리 효과

const Toggle = ({
  children,
  ...rest
}: React.PropsWithChildren<ToggleProps>) => {
  const { toggleRef, isOpen, disabled, changeStyle, displayBorder } =
    useDropdownContext();

  return (
    <ToggleContainer
      {...rest}
      ref={toggleRef}
      aria-expanded={isOpen}
      aria-disabled={disabled}
      disabled={disabled}
      changeStyle={changeStyle}
      displayBorder={displayBorder}
    >
      <ButtonText2>{children}</ButtonText2>
      <ChevronIcon />
    </ToggleContainer>
  );
};

const OptionList = ({
  children,
  ...rest
}: PropsWithChildren<OptionListProps>) => {
  const { isOpen } = useDropdownContext();
  const { optionListRef } = useDropdownContext();

  return isOpen ? (
    <Portal>
      <OptionListContainer {...rest} ref={optionListRef} role="listbox">
        {children}
      </OptionListContainer>
    </Portal>
  ) : null;
};

const OptionItem = ({
  children,
  selected,
  disabled,
  value,
  label,
  ...rest
}: PropsWithChildren<OptionItemProps>) => {
  return (
    <OptionItemContainer
      {...rest}
      role="option"
      aria-selected={selected}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      data-value={value}
    >
      {children || <ButtonText2>{label}</ButtonText2>}
    </OptionItemContainer>
  );
};

Dropdown.Toggle = Toggle;
Dropdown.OptionList = OptionList;
Dropdown.OptionItem = OptionItem;

const Portal = ({ children }: PropsWithChildren<{}>) => {
  const { container } = useDropdownContext();

  return ReactDOM.createPortal(children, container!);
};
