import { useRouter } from 'next/router';
import styled, { keyframes } from 'styled-components';
import en from '../../locale/en';
import ko from '../../locale/ko';
import { H1, SubtitleL } from 'global-styles';

export const FirstTitleBanner = () => {
  return (
    <FirstTitleBannerContainer>
      <MobileSize />
      <PCSize />
    </FirstTitleBannerContainer>
  );
};

const MobileSize = () => (
  <div>
    <FirstH1 className="banner-title mobile" tabIndex={-1}>
      <span>Enhance and Optimize</span>
    </FirstH1>
    <FirstH1 className="banner-title mobile" tabIndex={-1}>
      <span>Videos that fit into</span>
    </FirstH1>
    <FirstH1 className="banner-title mobile" tabIndex={-1}>
      <span>your use case and gratify</span>
    </FirstH1>
    <FirstH1 className="banner-title mobile" tabIndex={-1}>
      <span>Viewer&apos;s Needs</span>
    </FirstH1>
  </div>
);

const PCSize = () => (
  <div>
    <FirstH1 className="banner-title pc" tabIndex={-1}>
      <span>Enhance and Optimize</span>
    </FirstH1>
    <FirstH1 className="banner-title pc" tabIndex={-1}>
      <span>Videos that fit into your use case</span>
    </FirstH1>
    <FirstH1 className="banner-title pc" tabIndex={-1}>
      <span>and gratify Viewer&apos;s Needs</span>
    </FirstH1>
  </div>
);

export const FirstDescBanner = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;

  return (
    <DescBannerContainer>
      <DescSubtitleL tabIndex={-1}>
        <span>{t.banner[0].first}</span>
      </DescSubtitleL>
      <DescSubtitleL tabIndex={-1}>
        <span>{t.banner[0].second}</span>
      </DescSubtitleL>
    </DescBannerContainer>
  );
};

export const SecondTitleBanner = () => {
  return (
    <FirstTitleBannerContainer>
      <FirstH1 tabIndex={-1}>A provider of</FirstH1>
      <FirstH1 tabIndex={-1}>AI-based video processing</FirstH1>
      <FirstH1 tabIndex={-1}>and encoding solutions</FirstH1>
    </FirstTitleBannerContainer>
  );
};

export const SecondDescBanner = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;

  return (
    <DescBannerContainer>
      <DescSubtitleL tabIndex={-1}>{t.banner[1].first}</DescSubtitleL>
      <DescSubtitleL tabIndex={-1}>{t.banner[1].second}</DescSubtitleL>
    </DescBannerContainer>
  );
};

export const txtAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0,100%,0);
    } 100% {
    opacity: 1;
    transform: translate3d(0,0,0);
    }
`;

const FirstH1 = styled(H1)`
  color: #fff;
  overflow-y: hidden;
  span {
    display: block;
    animation: ${txtAnimation} 1.5s 0.3s cubic-bezier(0.4, 0, 0.2, 1) backwards;
  }
`;

const FirstTitleBannerContainer = styled.div`
  text-align: center;
  position: relative;
  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media screen and (max-width: 599px) {
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

const DescBannerContainer = styled.div`
  text-align: center;
  margin-top: 32px;
  overflow-y: hidden;
  position: relative;

  span {
    display: block;
    animation: ${txtAnimation} 1.5s 1.3s cubic-bezier(0.4, 0, 0.2, 1) backwards;
  }
  @media screen and (max-width: 904px) {
    margin-top: 16px;
  }
`;

const DescSubtitleL = styled(SubtitleL)`
  color: #fff;
`;
