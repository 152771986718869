import { useEffect } from 'react';
import { ToggleContextValue } from '../types';

type KeyDownParams = {
  toggleRef: ToggleContextValue['toggleRef'];
  optionListRef: ToggleContextValue['optionListRef'];
  close: () => void;
  toggle: () => void;
  onChange: ToggleContextValue['onChange'];
};

export const useKeydown = ({
  toggleRef,
  optionListRef,
  close,
  toggle,
  onChange,
}: KeyDownParams) => {
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.repeat || e.isComposing || e.keyCode === 229) return;

    const [toggleElement, optionListElem] = [
      toggleRef?.current,
      optionListRef?.current,
    ];

    const target = e.target as HTMLElement;
    const isTargetToggleElement =
      target === toggleElement || toggleElement?.contains(target);
    const isTargetOptionElement =
      target === optionListElem || optionListElem?.contains(target);

    if (!isTargetToggleElement && !isTargetOptionElement) return;

    const pressedKey = e.key;
    // esc 누르면 혹은 tab 누르면 close
    if (pressedKey === 'Escape' || pressedKey === 'Tab') {
      if (optionListElem) {
        e.preventDefault();
        toggleElement?.focus();
        close();
      }
    }

    // spacebar 혹은 enter -> onChange 실행 + close + focus 토글로 회복
    else if (e.code === 'Space' || pressedKey === 'Enter') {
      e.preventDefault();
      if (target.children) {
        const anchorChild = Array.from(target.children!).find(
          // const anchorChild = [...target.children!].find(
          item => item.tagName === 'A'
        ) as HTMLAnchorElement;
        if (anchorChild) {
          anchorChild.click();
          close();
          toggleElement?.focus();
          return;
        }
      }
      if (isTargetToggleElement) toggle();
      else {
        // const optionList = [...optionListElem!.children] as HTMLLIElement[];
        const optionList = Array.from(
          optionListElem!.children
        ) as HTMLLIElement[];
        const pressedOption = optionList
          .filter(option => option.ariaDisabled !== 'true')
          .find(option => option.contains(target) || option === target);

        if (pressedOption) {
          const newValue = pressedOption.dataset.value as string;
          onChange(e, newValue);
          close();
          toggleElement?.focus();
        }
      }
    }

    // 방향키 위 아래 누를경우 포커스 변경
    else if (pressedKey === 'ArrowUp' || pressedKey === 'ArrowDown') {
      if (!optionListElem) return;
      e.preventDefault();
      // const optionListElems = [...optionListElem!.children] as HTMLLIElement[];
      const optionListElems = Array.from(
        optionListElem!.children
      ) as HTMLLIElement[];
      if (isTargetToggleElement) {
        if (optionListElems[0]) optionListElems[0]?.focus();
      } else {
        const focusedElement = document.activeElement;
        const currentFocusedOptionIndex = optionListElems.findIndex(
          option => option === focusedElement
        );

        if (currentFocusedOptionIndex !== -1) {
          const direction = pressedKey === 'ArrowUp' ? -1 : 1;
          const newFocusOptionIdx =
            pressedKey === 'ArrowUp'
              ? Math.min(currentFocusedOptionIndex + direction, 0)
              : Math.max(
                  currentFocusedOptionIndex + direction,
                  optionListElems.length - 1
                );
          optionListElems[newFocusOptionIdx]?.focus();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [toggleRef, optionListRef, close, toggle]);
};
