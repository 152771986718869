import { MenuExplainENText, MenuExplainKRText } from 'global-styles';
import {
  BlogMenuItemCustomComponent,
  ContactMenuItemCustomComponent,
} from './CustomComponents';
import { CompanyProps, ItemProps } from './SubMenusMenuAreaTemplate';

export const productMenuItems: ItemProps[] = [
  {
    itemName: 'DeepField-SR1/SR2',
    itemBrief: {
      en: [
        <MenuExplainENText
          className="p-item-brief"
          key="A clear picture quality"
          as="p"
        >
          A clear picture quality
        </MenuExplainENText>,
        <MenuExplainENText
          className="p-item-brief"
          as="p"
          key="improvement solution"
        >
          improvement solution
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText className="p-item-brief" key="디테일을 살린" as="p">
          디테일을 살린
        </MenuExplainKRText>,
        <MenuExplainKRText
          className="p-item-brief"
          as="p"
          key="또렷한 화질개선 솔루션"
        >
          또렷한 화질개선 솔루션
        </MenuExplainKRText>,
      ],
    },
    hashItems: {
      en: ['#Upscaling', '#Improvement'],
      ko: ['#업스케일링', '#화질개선'],
    },
    href: '/product/sr1sr2/#product-section',
  },
  {
    itemName: 'Pulsar-AV1',
    itemBrief: {
      en: [
        <MenuExplainENText className="p-item-brief" key="More video," as="p">
          More video,
        </MenuExplainENText>,
        <MenuExplainENText className="p-item-brief" as="p" key="with less data">
          with less data
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText
          className="p-item-brief"
          key="기존 코덱보다 적은 데이터로"
          as="p"
        >
          기존 코덱보다 적은 데이터로
        </MenuExplainKRText>,
        <MenuExplainKRText
          className="p-item-brief"
          as="p"
          key="더 많은 비디오 지원"
        >
          더 많은 비디오 지원
        </MenuExplainKRText>,
      ],
    },
    hashItems: {
      en: ['#Encoding', '#Reduced Cost'],
      ko: ['#고성능인코더', '#비용절감'],
    },
    href: '/product/av1/#product-section',
  },
  {
    itemName: 'DeepField-SR3',
    itemBrief: {
      en: [
        <MenuExplainENText
          className="p-item-brief"
          key="Improving the efficiency of"
          as="p"
        >
          Improving the efficiency of
        </MenuExplainENText>,
        <MenuExplainENText
          className="p-item-brief"
          as="p"
          key="computer vision systems"
        >
          computer vision systems
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText className="p-item-brief" key="화질 개선으로" as="p">
          화질 개선으로
        </MenuExplainKRText>,
        <MenuExplainKRText
          className="p-item-brief"
          as="p"
          key="컴퓨터 비전 시스템의 효율 향상"
        >
          컴퓨터 비전 시스템의 효율 향상
        </MenuExplainKRText>,
      ],
    },
    hashItems: {
      en: ['#Improve Low-Light', '#Text Sharpness'],
      ko: ['#조도개선', '#텍스트선명도개선'],
    },
    href: '/product/sr3/#product-section',
  },
  {
    itemName: 'Pulsar-AVIF',
    itemBrief: {
      en: [
        <MenuExplainENText
          className="p-item-brief"
          key="Reduce image capacity"
          as="p"
        >
          Reduce image capacity
        </MenuExplainENText>,
        <MenuExplainENText
          className="p-item-brief"
          as="p"
          key="and network usage in half"
        >
          and network usage in half
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText
          className="p-item-brief"
          key="사진 용량과 네트워크"
          as="p"
        >
          사진 용량과 네트워크
        </MenuExplainKRText>,
        <MenuExplainKRText
          className="p-item-brief"
          as="p"
          key="사용량을 반으로"
        >
          사용량을 반으로
        </MenuExplainKRText>,
      ],
    },
    hashItems: {
      en: ['#Fast Image Compression'],
      ko: ['#빠른이미지압축'],
    },
    href: '/product/avif/#product-section',
  },
  {
    itemName: 'DeepField-PQO',
    itemBrief: {
      en: [
        <MenuExplainENText
          className="p-item-brief"
          key="By optimizing video pixels"
          as="p"
        >
          By optimizing video pixels
        </MenuExplainENText>,
        <MenuExplainENText
          className="p-item-brief"
          as="p"
          key="Improved encoding efficiency"
        >
          Improved encoding efficiency
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText
          className="p-item-brief"
          key="비디오 픽셀을 최적화하여"
          as="p"
        >
          비디오 픽셀을 최적화하여
        </MenuExplainKRText>,
        <MenuExplainKRText
          className="p-item-brief"
          as="p"
          key="인코딩 효율 향상"
        >
          인코딩 효율 향상
        </MenuExplainKRText>,
      ],
    },
    hashItems: {
      en: ['#Support Codec', '#Reduced Cost'],
      ko: ['#코덱지원', '#송출비용절감'],
    },
    href: '/product/pqo/#product-section',
  },
  {
    itemName: 'Pulsar-VMAF',
    itemBrief: {
      en: [
        <MenuExplainENText className="p-item-brief" key="Very fast" as="p">
          Very fast
        </MenuExplainENText>,
        <MenuExplainENText
          className="p-item-brief"
          as="p"
          key="VMAF accelerator"
        >
          VMAF accelerator
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText className="p-item-brief" key="매우 빠른" as="p">
          매우 빠른
        </MenuExplainKRText>,
        <MenuExplainKRText className="p-item-brief" as="p" key="VMAF 가속기">
          VMAF 가속기
        </MenuExplainKRText>,
      ],
    },
    hashItems: {
      en: ['#Semiconductor', '#Reduced Cost'],
      ko: ['#반도체설계', '#컴퓨팅비용절감'],
    },
    href: '/product/vmaf/#product-section',
  },
];

export const companyMenuItems: CompanyProps[] = [
  {
    itemType: 'link',
    itemName: 'About',
    href: '/about',
    itemBrief: {
      en: [
        <MenuExplainENText className="p-item-brief" key="view more" as="p">
          For more about &apos;BLUEDOT&apos;
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText className="p-item-brief" key="매우 빠른" as="p">
          &apos;블루닷&apos;은 이런 기업입니다.
        </MenuExplainKRText>,
      ],
    },
  },
  {
    itemType: 'custom',
    itemName: 'Blog',
    customComponent: <BlogMenuItemCustomComponent />,
    itemBrief: {
      en: [],
      ko: [],
    },
  },
  {
    itemType: 'link',
    itemName: 'Event',
    href: '/event',
    itemBrief: {
      en: [
        <MenuExplainENText className="p-item-brief" key="view more" as="p">
          Meet BLUEDOT&apos;s technology on the spot.
        </MenuExplainENText>,
      ],
      ko: [
        <MenuExplainKRText className="p-item-brief" key="매우 빠른" as="p">
          블루닷의 기술을 현장에서 직접 만나보세요.
        </MenuExplainKRText>,
      ],
    },
  },
  {
    itemType: 'custom',
    itemName: 'Contact',
    customComponent: <ContactMenuItemCustomComponent />,
    itemBrief: {
      en: [],
      ko: [],
    },
  },

  // {
  //   itemType: 'fake',
  //   itemName: '',
  //   customComponent: <FakeItemComponent />,
  //   itemBrief: {
  //     en: [],
  //     ko: [],
  //   },
  // },
];
