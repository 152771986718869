import { useRouter } from 'next/router';
import styled from 'styled-components';
import en from '../../locale/en';
import ko from '../../locale/ko';
import { LinkedInIcon, LocationIcon, NotionIcon } from 'assets/icons';
import { gridSystemMargin } from '../Layout';
import bluedotLogoC from '../../public/asset/bluedot-logo-c.svg';
import { PrivatePolicyPopup } from 'components/Popup/index';
import { usePrivatePolicyModal } from 'hooks/useContactModal';
import Link from 'next/link';
import {
  GRAY10,
  GRAY50,
  GRAY60,
  GRAY70,
  GRAY80,
  BodyTextM,
  BodyTextMStrong,
  H5,
  ButtonText2,
} from 'global-styles';
import { Dropdown } from 'dropdown';

const Footer = () => {
  const { locale } = useRouter();
  const t = locale === 'en' ? en : ko;
  const { handleClosePrivatePolicyModal, isPrivatePolicyModalOpen } =
    usePrivatePolicyModal();

  return (
    <FooterContainer>
      <div className="top-container">
        <div className="top-left-wrapper">
          <div className="logo-wrapper">
            <img src={bluedotLogoC.src} alt="bluedot inc." />
          </div>
          <H5 className="footer-h5" as="h2">
            {t.footer.companyName}
          </H5>
        </div>

        <div className="top-right-wrapper">
          <a
            href="https://www.linkedin.com/company/bluedot-io"
            className="sns-link"
            aria-label={
              locale === 'en'
                ? 'Go to the Linked in page of bluedot inc.'
                : '블루닷 링크드인 페이지로 이동하기.'
            }
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon color={GRAY50} />
          </a>
          <a
            href="https://www.notion.so/blue-dot/DeepField-SR-AI-based-Super-Resolution-Accelerated-by-FPGA-af4d1ab39f374db3a927c0cbd10a96c0"
            className="sns-link notion"
            target="_blank"
            aria-label={
              locale === 'en'
                ? 'Go to the notion page of bluedot inc.'
                : '블루닷 노션 페이지로 이동하기.'
            }
            rel="noreferrer"
          >
            <NotionIcon color={GRAY50} />
          </a>
          <Dropdown
            disabled={false}
            onChange={() => {}}
            changeStyle
            isPositionFixed={false}
          >
            <Dropdown.Toggle>Family Site</Dropdown.Toggle>
            <Dropdown.OptionList>
              <Dropdown.OptionItem
                role="link"
                selected={false}
                disabled={false}
                value="Kokoon"
                label="Kokoon"
                tabIndex={-1}
              >
                <a
                  href="https://www.kokoon.cloud"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'block',
                    height: '100%',
                  }}
                >
                  <ButtonText2>Kokoon</ButtonText2>
                </a>
              </Dropdown.OptionItem>
            </Dropdown.OptionList>
          </Dropdown>
        </div>
      </div>

      <div className="bottom-container">
        <H5 className="footer-h5" as="h2">
          {t.footer.companyName}
        </H5>

        <div className="location-wrapper">
          <LocationIcon />
          <BodyTextM className="location-text">{t.footer.address}</BodyTextM>
        </div>

        <div className="info-wrapper">
          <dl>
            <BodyTextMStrong as="dt">MAIL</BodyTextMStrong>
            <BodyTextM as="dd">contact@blue-dot.io</BodyTextM>
            <BodyTextM as="dd">hr@blue-dot.io</BodyTextM>
          </dl>

          <div className="border" />

          <dl className="tel">
            <BodyTextMStrong as="dt">TEL</BodyTextMStrong>
            <BodyTextMStrong className="s-dt">Sales</BodyTextMStrong>
            <BodyTextM as="dd" color={GRAY50}>
              +82 2 6205 0814
            </BodyTextM>
            <BodyTextMStrong className="s-dt s-dt-ir">IR</BodyTextMStrong>
            <BodyTextM as="dd" color={GRAY50}>
              +82 2 6205 0812
            </BodyTextM>
          </dl>

          <div className="border" />

          <dl className="fax">
            <BodyTextMStrong as="dt">FAX</BodyTextMStrong>
            <BodyTextM as="dd" color={GRAY50}>
              +82 2 6205 0812
            </BodyTextM>
          </dl>
        </div>

        <Link href={{ pathname: '/privacy-policy' }}>
          <BodyTextMStrong as="a" className="pri-pol-btn">
            {locale === 'en' ? 'Privacy policy' : '개인정보처리방침'}
          </BodyTextMStrong>
        </Link>
        <BodyTextM as="small" className="copyright">
          © 2022. BLUEDOT INC. all rights reserved.
        </BodyTextM>
      </div>

      {isPrivatePolicyModalOpen && (
        <PrivatePolicyPopup
          handleClosePrivatePolicy={handleClosePrivatePolicyModal}
        />
      )}
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  padding: 56px 0 72px;
  ${gridSystemMargin}
  .pri-pol-btn {
    color: ${GRAY50};
    padding: 0;
    margin: 0 0 8px 0;
  }
  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo-wrapper {
    width: 230px;
    height: 36px;
  }
  .top-left-wrapper {
    display: flex;
    align-items: center;
  }
  .footer-h5 {
    margin-left: 70px;
    color: ${GRAY10};
  }
  .top-right-wrapper {
    display: flex;
    align-items: center;
    .sns-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
    }
    .sns-link.notion {
      margin-right: 16px;
    }
  }
  .bottom-container {
    color: ${GRAY50};
    margin-top: 24px;
    margin-left: 299px;
    .footer-h5 {
      display: none;
    }
  }
  .location-wrapper {
    display: flex;
    align-items: flex-start;
    svg {
      margin-top: 4px;
      margin-right: 11px;
    }
    .location-text {
      margin-top: 2px;
    }
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    white-space: nowrap;

    dl {
      display: flex;
    }
    dt {
      width: 40px;
      margin-right: 8px;
    }
    dd {
      /* safari text color 적용 이슈 */
      -webkit-text-fill-color: ${GRAY50};
      letter-spacing: -0.05rem;
    }
    dd + dd {
      margin-left: 16px;
    }
    .s-dt {
      color: ${GRAY60};
      margin-right: 8px;
    }
    .s-dt.s-dt-ir {
      margin-left: 16px;
    }

    @media screen and (max-width: 1239px) {
      .tel,
      .fax {
        margin-top: 2px;
      }
    }
  }
  .border {
    flex-shrink: 0;
    background: ${GRAY80};
    display: block;
    width: 1px;
    height: 14px;
    margin: 0 16px;
  }

  .copyright {
    display: block;
    color: ${GRAY70};
  }
  @media screen and (max-width: 1410px) {
    .info-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
    .border {
      display: none;
    }
  }

  @media screen and (max-width: 1239px) and (min-width: 905px) {
    .bottom-container {
      margin-left: 222px;
    }
    .logo-wrapper {
      width: 153px;
      height: 24px;
    }
  }

  @media screen and (max-width: 904px) and (min-width: 600px) {
    .footer-h5 {
      display: none;
    }
    .bottom-container {
      margin-left: 0;
      .footer-h5 {
        margin-left: 0;
        margin-bottom: 24px;
        display: block;
      }
    }
    .logo-wrapper {
      width: 153px;
      height: 24px;
    }
  }
  @media screen and (max-width: 599px) {
    padding: 32px 0;
    .top-container {
      flex-wrap: wrap;
    }
    .top-left-wrapper,
    .top-right-wrapper {
      width: 100%;
    }
    .top-right-wrapper {
      margin-top: 16px;
    }
  }
  @media screen and (max-width: 600px) and (min-width: 361px) {
    .footer-h5 {
      display: none;
    }
    .bottom-container {
      margin-left: 0;
      .footer-h5 {
        margin-left: 0;
        margin-bottom: 24px;
        display: block;
      }
    }
    .logo-wrapper {
      width: 153px;
      height: 24px;
    }
  }
  @media screen and (max-width: 360px) {
    .footer-h5 {
      display: none;
    }
    .bottom-container {
      margin-left: 0;
      .footer-h5 {
        margin-left: 0;
        margin-bottom: 24px;
        display: block;
      }
    }
    .top-container {
      flex-wrap: wrap;
      margin-top: 16px;
      .top-left-wrapper,
      .top-right-wrapper {
        width: 100%;
      }
      .top-right-wrapper {
        margin-top: 16px;
      }
    }
    .logo-wrapper {
      width: 153px;
      height: 24px;
    }
  }
`;
