import Image from 'next/image';
import { ElementType, useRef } from 'react';
import styled from 'styled-components';
import coverImg from 'public/asset/01-main-banner/contents1/mainbanner-cover.jpg';

interface Props {
  useVideo: boolean;
  MainComponent: ElementType;
  DescComponent: ElementType;
  movePath: string;
  /**
   * useVideo가 true일 경우, 비디오 파일의 경로입니다.
   */
  backgroundImagePath: string;
  blurImgPath?: string;
}

export const BannerSlideTemplate = ({
  useVideo,
  MainComponent,
  DescComponent,
  backgroundImagePath,
}: Props) => {
  const wrapperRef = useRef<HTMLElement>(null);
  return (
    <BannerSlideTemplateContainer ref={wrapperRef}>
      <div className="banner-img-wrapper">
        {useVideo ? (
          <>
            <Image
              src={coverImg}
              layout="fill"
              alt="bluedot"
              className="banner-img"
              quality={30}
              priority
              sizes="(min-width: 906px) 90vw,
                 (min-width: 600px) 85vw,
                 (max-width: 599px) 75vw"
              loading="eager"
            />
            {backgroundImagePath && backgroundImagePath.length !== 0 && (
              <video
                src={backgroundImagePath}
                autoPlay
                playsInline
                poster={backgroundImagePath}
                muted
                loop
                className="banner-vid"
              />
            )}
          </>
        ) : (
          <Image
            src={backgroundImagePath}
            layout="fill"
            alt="bluedot"
            className="banner-img"
            quality={85}
            priority
            sizes="(min-width: 906px) 90vw,
                 (min-width: 600px) 85vw,
                 (max-width: 599px) 75vw"
            loading="eager"
          />
        )}
      </div>
      <MainComponent />
      <DescComponent />
    </BannerSlideTemplateContainer>
  );
};

const BannerSlideTemplateContainer = styled.section`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;

  @media screen and (min-width: 1440px) {
    width: 100%;
    padding: 0 96px;
  }
  @media screen and (min-width: 1240px) and (max-width: 1439px) {
    width: 100%;
    padding: 0 96px;
  }
  @media screen and (min-width: 905px) and (max-width: 1239px) {
    width: 100%;
    padding: 0 56px;
  }
  @media screen and (min-width: 600px) and (max-width: 904px) {
    width: 100%;
    padding: 0 32px;
  }
  @media screen and (max-width: 599px) {
    width: 100%;
    padding: 0 16px;
  }

  .banner-img-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    /* z-index: -1; */
  }

  .banner-vid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
`;
