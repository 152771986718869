import { NormalArrowIcon } from 'assets/icons';
import { useGetTheTwoLatestPosts } from 'react-query/blogHooks';
import {
  ALLSTROKE,
  MenuExplainENText,
  MenuExplainKRText,
  MenuNameText,
} from 'global-styles';
import { useContactModal } from 'hooks/useContactModal';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Link from 'next/link';

export const BlogMenuItemCustomComponent = () => {
  const { data } = useGetTheTwoLatestPosts();

  return (
    <Link href="/blog">
      <BlogMenuItemCustomComponentContainer
        className="grid-item-wrapper"
        as="a"
      >
        <div className="grid-item">
          <MenuNameText className="p-item-name">Blog</MenuNameText>

          <ul className="p-brief-wrapper">
            {data?.posts &&
              data.posts.length !== 0 &&
              data.posts.map(post => (
                <MenuExplainKRText as="li" key="bluedot" className="post-title">
                  <Link href={`/blog/post/${post.id}`}>
                    <a>
                      {post.title
                        .replaceAll('&#8216;', '‘')
                        .replaceAll('&#8217;', '’')}
                    </a>
                  </Link>
                </MenuExplainKRText>
              ))}
          </ul>

          <div className="goto-btn">
            <NormalArrowIcon color="#fff" />
          </div>
        </div>
      </BlogMenuItemCustomComponentContainer>
    </Link>
  );
};

const BlogMenuItemCustomComponentContainer = styled.div`
  border-left: 1px solid ${ALLSTROKE};
  .p-brief-wrapper {
    padding: 0 20px 0 12px;
  }
  .post-title {
    max-width: 270px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    position: relative;
    padding-left: 14px;
  }
  .post-title a {
    color: inherit;
  }
  .post-title:hover {
    text-decoration: underline;
  }
  .post-title::before {
    content: '';
    display: block;
    top: 50%;
    left: 2px;
    transform: translate(-50%, 0);
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
  }
`;

export const ContactMenuItemCustomComponent = () => {
  const { locale } = useRouter();
  const { handleOpenContactModal } = useContactModal();

  return (
    <ContactMenuItemCustomComponentContainer className="grid-item-wrapper">
      <div className="grid-item">
        <MenuNameText className="p-item-name">Contact</MenuNameText>

        <div className="p-brief-wrapper">
          {locale === 'en' ? (
            <>
              <MenuExplainENText as="p" key="Have a question?">
                Have a question?
              </MenuExplainENText>
              <MenuExplainENText as="p" key="Please contact us.">
                Please{' '}
                <MenuExplainENText
                  style={{
                    color: '#fff',
                    textDecoration: 'underline',
                    padding: 0,
                  }}
                  as="button"
                  onClick={handleOpenContactModal}
                >
                  contact us.
                </MenuExplainENText>
              </MenuExplainENText>
            </>
          ) : (
            <MenuExplainKRText as="p" key="VNAF 가속기">
              궁금한 사항은{' '}
              <MenuExplainKRText
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                  padding: 0,
                }}
                as="button"
                onClick={handleOpenContactModal}
              >
                여기
              </MenuExplainKRText>
              로 문의바랍니다.
            </MenuExplainKRText>
          )}
        </div>
      </div>
    </ContactMenuItemCustomComponentContainer>
  );
};

const ContactMenuItemCustomComponentContainer = styled.div`
  border-left: 1px solid ${ALLSTROKE};
`;

export const FakeItemComponent = () => {
  return <FakeMenuItemCustomComponentContainer className="grid-item-wrapper" />;
};

const FakeMenuItemCustomComponentContainer = styled.div`
  & + & {
    border-left: 1px solid ${ALLSTROKE};
  }
`;
