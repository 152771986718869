import { useCallback, useEffect } from 'react';
import { ToggleContextValue } from '../types';

type Params = {
  toggleRef: ToggleContextValue['toggleRef'];
  optionListRef: ToggleContextValue['optionListRef'];
  close: () => void;
  toggle: () => void;
  onChange: ToggleContextValue['onChange'];
};

export const useClick = ({
  toggleRef,
  optionListRef,
  toggle,
  close,
  onChange,
}: Params) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      const [toggleElement, optionListElem] = [
        toggleRef?.current,
        optionListRef?.current,
      ];

      const target = e.target as HTMLElement;
      const isTargetToggleElement =
        target === toggleElement || toggleElement?.contains(target);
      const isTargetOptionElement =
        target === optionListElem || optionListElem?.contains(target);

      if (!isTargetOptionElement && !isTargetToggleElement) {
        close();
      } else if (isTargetToggleElement) {
        toggle();
      } else if (isTargetOptionElement) {
        const clickedOption = (
          Array.from(optionListElem!.children) as HTMLLIElement[]
        )
          // const clickedOption = ([...optionListElem!.children] as HTMLLIElement[])
          .filter(option => option.ariaDisabled !== 'true')
          .find(option => option.contains(target) || option === target);

        if (clickedOption) {
          const newValue = clickedOption.dataset.value as string;
          onChange(e as MouseEvent, newValue);
          close();
        }
      }
    },
    [toggleRef, optionListRef, close, toggle]
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [handleClick]);
};
