import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const gridSystemMargin = css`
  @media screen and (min-width: 1440px) {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1240px) and (max-width: 1439px) {
    width: calc(100% - 192px);
    margin: 0 96px;
  }
  @media screen and (min-width: 905px) and (max-width: 1239px) {
    width: calc(100% - 112px);
    margin: 0 56px;
  }
  @media screen and (min-width: 600px) and (max-width: 904px) {
    width: calc(100% - 64px);
    margin: 0 32px;
  }
  @media screen and (max-width: 599px) {
    width: calc(100% - 32px);
    margin: 0 16px;
  }
`;

export const gridSystemPadding = css`
  @media screen and (min-width: 1440px) {
    width: 100%;
    max-width: 1200px;
    padding: 0 96px;
  }
  @media screen and (min-width: 1240px) and (max-width: 1439px) {
    width: 100%;
    padding: 0 96px;
  }
  @media screen and (min-width: 905px) and (max-width: 1239px) {
    width: 100%;
    padding: 0 56px;
  }
  @media screen and (min-width: 600px) and (max-width: 904px) {
    width: 100%;
    padding: 0 32px;
  }
  @media screen and (max-width: 599px) {
    width: 100%;
    padding: 0 16px;
  }
`;

const Layout = ({ children }: { children: ReactNode }) => {
  return <LayoutContainer>{children}</LayoutContainer>;
};

export default Layout;

const LayoutContainer = styled.div`
  ${gridSystemMargin};
`;
