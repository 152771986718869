import styled, { css } from 'styled-components';
import { TechBox } from 'components/Global/index';
import { H2, LabelText, BBPALE } from 'global-styles';
import techImg from 'public/asset/02-section1/icon/main-section1-icon@2x.png';
import { translateAppear, opacityAppear } from './ProductTemplate';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

export const CoreTech = () => {
  const [targetRef, isVisible] = useInView({
    threshold: 0.3,
    fallbackInView: true,
    triggerOnce: true,
  });

  return (
    <CoreTechContainer
      ref={targetRef}
      isVisible={isVisible}
      className="core-tech-container"
    >
      <div className="upper" />
      <div className="bottom" />
      <div className="tech-wrapper">
        <CoreTechLabelText>
          <span className="translate-wrapper">Core Technologies</span>
        </CoreTechLabelText>
        <div className="tech-icon-wrapper">
          <Image
            src={techImg.src}
            alt=""
            layout="fill"
            objectFit="contain"
            quality={70}
          />
        </div>
        <CoreTechH2 className="vas" isVisible={isVisible}>
          <span className="translate-wrapper">
            Video Application Specific
            <span className="ai-based-pc ai-based">AI-based</span>
          </span>
        </CoreTechH2>
        <CoreTechH2 isVisible={isVisible}>
          <span className="translate-wrapper">
            <span className="ai-based-mo ai-based">AI-based</span>
            Processing and
            <span className="lb"> Compression Technologies</span>
          </span>
        </CoreTechH2>
        <div className="tech-box-wrapper">
          <TechBox idx={0} />
          <TechBox idx={1} />
          <TechBox idx={2} />
          <TechBox idx={3} />
        </div>
      </div>
    </CoreTechContainer>
  );
};

interface CoreTechContainerProps {
  isVisible: boolean;
}

const CoreTechContainer = styled.section`
  position: relative;
  width: 100vw;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 640px;
  text-align: center;
  overflow-y: hidden;

  .tech-icon-wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 16px auto;
  }
  @media screen and (min-width: 1440px) {
    padding: 0 96px;
  }
  @media screen and (max-width: 1439px) {
    padding: 0 96px;
  }
  @media screen and (max-width: 1239px) {
    padding: 0 56px;
  }
  @media screen and (max-width: 904px) {
    padding: 0 32px;
    .tech-icon-wrapper {
      width: 60px;
      height: 60px;
      margin: 8px auto;
    }
  }
  @media screen and (max-width: 599px) {
    padding: 0 16px;

    .tech-icon-wrapper {
      width: 48px;
      height: 48px;
    }
  }
  .tech-wrapper {
    transform: translate3d(0, -50%, 0);
    top: 50%;
    left: 0;
    right: 0;
    position: absolute;
    /* device높이 700px 이하부터는 top(마진역할)이 68px로 최소값 설정되어 헤더가 가리지 않도록 한다. */
    @media screen and (max-height: 700px) {
      top: 68px;
      transform: none;
    }
  }

  .tech-box-wrapper::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .tech-box-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 56px;
    scroll-snap-type: x mandatory;
    opacity: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    display: flex;
    gap: 16px;
    width: 100%;
    ${({ isVisible }: CoreTechContainerProps) =>
      isVisible &&
      css`
        animation: ${opacityAppear} 1s 0.7s ease-out forwards;
      `};

    @media screen and (min-width: 1440px) {
      padding: 56px auto 0;
    }
    @media screen and (min-width: 1240px) and (max-width: 1439px) {
      padding: 56px 96px 0;
    }
    @media screen and (min-width: 905px) and (max-width: 1239px) {
      padding: 56px 56px 0;
    }
    @media screen and (min-width: 600px) and (max-width: 904px) {
      padding: 32px 32px 0;
    }
    @media screen and (max-width: 599px) {
      padding: 16px 16px 0;
    }

    @media screen and (min-width: 1439px) {
      justify-content: center;
    }
  }
  .upper,
  .bottom {
    position: absolute;
    left: 0;
    right: 0;
  }
  .upper {
    top: 0;
    bottom: 33.33%;
    background: radial-gradient(
      125.21% 125.21% at 50% -2.92%,
      #33466c 0%,
      #202140 42.97%,
      #0b183a 99.99%,
      #152346 100%
    );
  }
  .bottom {
    top: 66.67%;
    bottom: 0;
    background: radial-gradient(
      100% 100% at 50% 0%,
      #f5f5f5 0%,
      #e7e6e6 0.01%,
      #f5f5f5 100%
    );
    @media screen and (max-width: 599px) {
      background: linear-gradient(
        180deg,
        #e7e6e6 0.16%,
        #f5f5f5 0.17%,
        #e8e8e8 100%
      );
    }
  }
`;

const CoreTechLabelText = styled(LabelText)`
  color: ${BBPALE};
  overflow-y: hidden;
`;

const CoreTechH2 = styled(H2)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow-y: hidden;
  .translate-wrapper {
    display: block;
    transform: translate3d(0, 300%, 0);
    margin: 0 auto;
    ${({ isVisible }: CoreTechContainerProps) =>
      isVisible &&
      css`
        animation: ${translateAppear} 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards;
      `};
    .lb {
      @media screen and (max-width: 600px) {
        display: block;
      }
    }
  }
  .ai-based {
    margin-left: 0.5ch;
    color: #2564ce;
  }
  .ai-based-mo {
    display: none;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    .ai-based-pc {
      display: none;
    }
    .ai-based-mo {
      display: unset;
      margin-left: 0;
      margin-right: 0.5ch;
    }
  }
`;
