/* eslint-disable no-unused-vars */

/**
  * Break points
  * @constant
  * @param {string}({
  * ( XL : width >= 1440px ),
  * ( L : 1440px > width >= 1240px )
  * ( M : 1240px > width >= 905px )
  * ( S : 904px > width >= 600px )
  * ( XS : 600px > width )
 })
 */
export const breakpoints = {
  XL: '@screen and (min-width: 1440px)',
  L: '@screen and (max-width: 1439px) and (min-width: 1240px)',
  M: '@screen and (max-width: 1239px) and (min-width: 905px)',
  S: '@screen and (max-width: 904px) and (min-width: 600px)',
  XS: '@screen and (max-width: 599px)',
  stL: '@screen and (max-width: 1439px)',
  stM: '@screen and (max-width: 1240px)',
  stS: '@screen and (max-width: 904px)',
};

const size = {
  XL: '1440px',
  L: '1240px',
  M: '905px',
  S: '600px',
  XS: '0px',
};

type MediaQueryStringObj = Record<
  keyof typeof size,
  (style: TemplateStringsArray) => string
>;

export const mediaQueryStyle = Object.keys(size).reduce((acc, key) => {
  acc[key] = (style: string) => {
    const mediaQuery = `@media screen and (min-width: ${size[key]}) { ${style} }`;
    return mediaQuery;
  };

  return acc;
}, {} as MediaQueryStringObj);

type StyleObj = {
  [key: string]: string;
};

export const generateCssStyle = (styleObj: StyleObj) => {
  const entries = Object.entries(styleObj);
  let styleString = ``;

  for (const [key, value] of entries) {
    styleString += `${key}: ${value};`;
  }

  return styleString;
};
