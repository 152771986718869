import { useBlogContext } from 'context/BlogContext';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
  getENPostList,
  getENRelatedPostList,
  getENTheTwoLatestPostInfo,
  getPostList,
  GetPostParmas,
  getRelatedPostList,
  getTheTwoLatestPostInfo,
  increaseENPostViewCount,
  increasePostViewCount,
} from './blogApi';
import { queryKeys } from './queryClient';

export const useIncreasePostViewCount = (postId: number) => {
  const postIdListRef = useRef<number[]>([]);
  const { locale } = useRouter();

  const { mutate: requestIncreasePostViewCount, data } = useMutation(
    locale === 'en'
      ? () => increaseENPostViewCount(postId)
      : () => increasePostViewCount(postId),
    {
      mutationKey: queryKeys.increasePostViewCount,
      onSuccess: () => {
        // 포스트 카운트 요청 성공시 onSuccess 콜백에서 세션스토리지의 postId 배열에 추가
        const newPostIdList = [...postIdListRef.current, postId];
        sessionStorage.setItem('postIds', JSON.stringify(newPostIdList));
        console.log(data);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'development' ||
      window?.location?.href?.includes('http://localhost')
    )
      return;
    let postIdList: string[] | number[] | null = JSON.parse(
      sessionStorage.getItem('postIds')
    );

    if (!postIdList) {
      sessionStorage.setItem('postIds', '[]');
      postIdList = [];
    } else postIdList = postIdList.map(id => +id);

    postIdListRef.current = postIdList as number[];

    const doesIdExist = (postIdList as number[]).includes(postId);

    if (doesIdExist) return;

    requestIncreasePostViewCount();
  }, [postId, requestIncreasePostViewCount, locale]);
};

export const usePostList = () => {
  const {
    state: { currPageNum, pageSize, category, orderBy, order, search },
  } = useBlogContext();
  const { locale } = useRouter();
  const reqParams: GetPostParmas = {
    page_num: currPageNum,
    page_size: pageSize,
    category,
    order_by: orderBy,
    order,
    search,
  };
  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      queryKeys.postList,
      currPageNum,
      pageSize,
      category,
      orderBy,
      order,
      search,
      locale,
    ],
    ({ pageParam = reqParams }) => {
      if (locale === 'en') return getENPostList(pageParam, search !== '');
      return getPostList(pageParam, search !== '');
    },
    {
      getNextPageParam: lastPage => {
        return lastPage?.total_page_count ||
          0 >= +lastPage?.curr_page_num + 1 ||
          0
          ? { ...reqParams, page_num: +lastPage?.curr_page_num + 1 || 0 }
          : undefined;
      },
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
  };
};

export const useRelatedPostList = (postId: number) => {
  const { locale } = useRouter();
  const { data, isLoading, isFetching, isError } = useQuery(
    [queryKeys.relatedPostList, postId, locale],
    locale === 'en'
      ? () => getENRelatedPostList(postId)
      : () => getRelatedPostList(postId)
  );

  return { data, isLoading, isFetching, isError };
};

export const useGetTheTwoLatestPosts = () => {
  const { locale } = useRouter();
  const query = useQuery(
    [queryKeys.getTheTwoLatestPosts, locale],
    locale === 'en'
      ? () => getENTheTwoLatestPostInfo()
      : () => getTheTwoLatestPostInfo(),
    {
      staleTime: 300,
    }
  );

  return query;
};

