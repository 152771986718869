import { RefObject, useEffect, useState } from 'react';

export const useCloseModalClickOutside = (node: RefObject<HTMLElement>) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const closeModal = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (node.current && !node.current.contains(target)) setIsOpen(false);
    };

    const closeModalESC = (e: KeyboardEvent) => {
      if (e.code === 'Escape') setIsOpen(false);
    };

    if (isOpen) {
      window.addEventListener('click', closeModal);
      window.addEventListener('keydown', closeModalESC);
    }
    return () => {
      window.removeEventListener('click', closeModal);
      window.removeEventListener('keydown', closeModalESC);
    };
  }, [isOpen, node]);

  return [isOpen, setIsOpen] as const;
};
