import videoSRImg from 'public/asset/03-section2/content1/content1@2x.png';
import vmafImg from 'public/asset/03-section2/content2/content2@2x.png';
import videoQualityImg from 'public/asset/03-section2/content3/content3@2x.png';
import avifEncoderImg from 'public/asset/03-section2/content4/content4@2x.png';
import av1EncoderImg from 'public/asset/03-section2/content5/content5@2x.png';

const en = {
  metaTag: {
    title: 'BLUEDOT | Video AI & Codec technology IP provider',
    description:
      'BLUEDOT develops Semiconductor IP on FPGA in data center to accelerate Video Processing and Video Encoding, delivering functions as a service.',
    keywords:
      'BLUEDOT, FPGA, Semiconductor IP, AV1, Xilinx, Cloud Computing, Super Resolution, AI Video Upscaling',
    og_type: 'website',
    og_title: 'BLUEDOT',
    og_description:
      'BLUEDOT develops Semiconductor IP on FPGA in data center to accelerate Video Processing and Video Encoding, delivering functions as a service.',
    og_url: 'https://blue-dot.io',
    twitter_card: 'summary_large_image',
    twitter_domain: 'blue-dot.io',
    twitter_title: 'BLUEDOT',
    twitter_description:
      'BLUEDOT develops Semiconductor IP on FPGA in data center to accelerate Video Processing and Video Encoding, delivering functions as a service.',
  },
  banner: [
    {
      first:
        'Using Deep Learning technology, you can increase the resolution and',
      second:
        'improve the quality of the video in ways that were never possible before.',
    },
    {
      first:
        'Develop next-generation video/image codecs such as AV1/AVIF which have higher',
      second: 'compression efficiency and low-cost encoding performance.',
    },
  ],
  productTemplateList: [
    {
      imgPath: videoSRImg.src,
      productName: 'Video Super Resolution',
      productPhrase:
        'Same video, another resolution, deliver better viewing experience',
      productDescription:
        'Many people are communicating online through videos in forms of advertising, content, and information retrieval. High quality videos can capture the minds of the viewers in the flood of video contents. BLUEDOT wants to help you produce high quality video contents for your business customers by utilizing our own Artificial Intelligence technology.',
      productFeatureList: [
        'Removes noise and creates clarity and texture details',
        'Increases color and convert resolution fitting the latest display size',
      ],
      reverseLayout: true,
    },
    {
      imgPath: vmafImg.src,
      productName: 'Video Quality Measurement (VMAF)',
      productPhrase: 'Human Perception based Video Quality Measuring',
      productDescription:
        'The increased UHD content services improved the visual experience of viewers. Quality measurements for serviced videos have become very important, and evaluation methods (VMAF) based on the quality perceived by viewers are widely used. BLUEDOT provides an easy-to-use VMAF measurement solution.',
      productFeatureList: [
        'Due to its fast speed, it can also be used to measure the quality of real-time ultra-high resolution video services',
        'Low compute infrastructure consumption makes it ideal for services at an affordable cost',
      ],
      reverseLayout: false,
    },
    {
      imgPath: videoQualityImg.src,
      productName: 'Perceptual Quality Optimizer',
      productPhrase: 'Saving Bitrate while Sustaining Video Quality',
      productDescription:
        'The biggest concern for service providers is the network traffic because related directly to the cost of the service with the explosive growth of videos. Effective management considering the quality of the video is also crucial since it affects the experience of the viewer. BLUEDOT provides technology to increase compression efficiency while maintaining the perceptual quality of video.',
      productFeatureList: [
        'Enhances encoding efficiency by pre-processing input video with AI technology',
        'It can be applied to real-time streaming service and used with any codec',
      ],
      reverseLayout: true,
    },
    {
      imgPath: avifEncoderImg.src,
      productName: 'AVIF Image Encoder',
      productPhrase: 'Most Efficient and Advanced Image Codec ',
      productDescription:
        'We use a lot of images on web pages for the efficient delivery of information. Service providers are burdened with the cost of network traffic as much as the amount of images has increased, and consumers are dissatisfied with the slow page loading time. BLUEDOT provides a very fast AVIF image encoder.',
      productFeatureList: [
        'It can be applied to real-time services due to its quick encoding',
        'Low use of computing resources reduce cost burden',
      ],
      reverseLayout: false,
    },
    {
      imgPath: av1EncoderImg.src,
      productName: 'AV1 Video Encoder',
      productPhrase: 'Very High Performance AV1 Encoder',
      productDescription:
        'The user demand for high game resolution and frame rate is increasing. Nevertheless, cloud gaming and e-sports streaming platform operators are experiencing great difficulties in implementing services due to the increase in network bandwidth usage and the difficulty of low-latency encoding. BLUEDOT offers AV1 encoder solution through the cloud, developed by semiconductor design technology.',
      productFeatureList: [
        'Due to its fast performance, it fits low-latency encoding without compromising video quality',
        'The most cost-effective AV1 encoder solution',
      ],
      reverseLayout: true,
    },
  ],
  contact: {
    title: 'Contact us',
    texts: [
      'Video upscaling cloud API usage, image quality testing, etc',
      'Please contact us if you have any inquiries about the product or technology.',
      'We will respond you as soon as possible',
    ],
    buttonText: 'Contact us now',
  },
  footer: {
    companyName: 'BLUEDOT INC.',
    address:
      '4th floor of Gangnam Telepia Building, 527 Eonju-ro, Gangnam-gu, Seoul',
  },
  buttonText: {
    tryDemo: 'Try Demo',
    learnMore: 'Learn more',
    contactUs: 'Contact us',
  },
  coreTech: [
    {
      description:
        'Develop technology where AI models trained for video processing algorithms such as noise removal, deblur, sharpen, upscale, and image quality improvement.',
      highlight:
        'Optimization of inference model for hardware where AI is utilized.',
    },
    {
      description:
        'Develop next-generation video/image codecs such as AV1/AVIF to support better compression efficiency and low-latency encoding system.',
      highlight: '16+ years of codec development and business experience.',
    },
    {
      description:
        'The developed solution is delivered in software SDK and hardware IP for a flexible and fast acceleration.',
      highlight: 'Easy integration into FFmpeg media framework.',
    },
    {
      description:
        'Accelerate solutions faster by implementing semiconductor design technology.',
      highlight:
        'Leverage new High-Level Synthesis design methodology to give faster deployment and flexible updates of solutions.',
    },
  ],
  contactPopup: {
    title: 'Contact to Sales',
    guideTexts: [
      'Please contact us if you have any inquiries about the product or technology such as video upscaling cloud API usage and image quality testing, etc. We will respond you as soon as possible.',
    ],
    inputGuide: {
      inputTitle: 'Select Theme',
      enterMessage: 'Enter Inquiries',
      options: [
        'Request AVIF Demo',
        'Technology',
        'Product',
        'Business',
        'Others',
      ],
    },
    privacyPolicy: {
      title: 'Notice',
      policies: [
        '1. BLUEDOT uses the data to provide an answer for your inquiry.',
        '2. If you agree with this Privacy Policy, your data will be stored for 3 year and after the expiration, it will be deleted immediately. Please check the Privacy Policy.',
      ],
      buttonText: ['Sending...', 'Send Email'],
      placeholders: {
        name: 'Full Name',
        email: 'Work Email',
        contact: 'Phone Number',
        company: 'Company',
        message: 'Message',
      },
      completeText: 'Email has sent successfully.',
    },
  },
  lnb: {
    deepfield: '/product/sr1sr2/',
    pulsar: '/product/av1/',
    srLink: '/product/sr1sr2/#product-section',
    pqoLink: '/product/pqo/#product-section',
    vmafLink: '/product/vmaf/#product-section',
    avifLink: '/product/avif/#product-section',
    av1Link: '/product/av1/#product-section',
    about: '/about',
    blog: '/blog',
    event: '/event',
  },
  lnbLabel: {
    deepfield: 'DeepField : Video Processing',
    pulsar: 'Pulsar : Video Encoding',
  },
};

export default en;
