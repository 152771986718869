import axios, { AxiosRequestConfig } from 'axios';

export const BLOG_URL = 'https://bluedotwebsite.blog/wp-json/base';
export const EN_BLOG_URL = 'https://blue-dot.blog/wp-json/base';
export const EVENT_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.NEXT_PUBLIC_EVENT_BASE_URL_DEV
    : process.env.NEXT_PUBLIC_EVENT_BASE_URL_PROD;
// 'https://kokoon-admin-api.kokoon.cloud/bluedot-page/v1';

const config: AxiosRequestConfig = { baseURL: BLOG_URL };
const eventConfig: AxiosRequestConfig = { baseURL: EVENT_URL };

export const axiosInstance = axios.create(config);
export const eventAxiosInstance = axios.create(eventConfig);
export const axiosEnInstance = axios.create({
  ...config,
  baseURL: EN_BLOG_URL,
});