import Link from 'next/link';
import styled from 'styled-components';
import bluedotLogoC from 'public/asset/bluedot-logo-c.svg';
import bluedotLogoW from 'public/asset/bluedot-logo-w.svg';

interface Props {
  isScrolled: boolean;
  isOpen: boolean;
}

export const LogoArea = ({ isScrolled, isOpen }: Props) => {
  return (
    <LogoAreaContainer isScrolled={isScrolled} as="h1">
      <Link href="/" scroll>
        {isScrolled && !isOpen ? (
          <a>
            <img src={bluedotLogoC.src} alt="bluedot inc." />
          </a>
        ) : (
          <a>
            <img src={bluedotLogoW.src} alt="bluedot inc." />
          </a>
        )}
      </Link>
    </LogoAreaContainer>
  );
};

const LogoAreaContainer = styled.h1<{ isScrolled: boolean }>`
  display: inline-block;
  transition: all 0.3s cubic-bezier(0.1, 0.57, 0.1, 1);
  width: 153px;
  height: 24px;
  a {
    display: inline-block;
    width: 100%;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1439px) {
    width: 128px;
    height: 20px;
  }
`;
