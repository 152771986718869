import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { MENUBG } from 'global-styles';
import { useAnimation } from 'hooks/useAnimation';
import { opacityAppear } from 'components/Home';
import { opacityDisappear } from 'avif-comparison/src/components/AppModal';
import { Dropdown } from 'components/Global';
import { NavMobileAccordion } from './MobileNavAccordion';
import SnsComponent from './SnsComponent';

type Props = {
  isScrolled: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MobileNavItems = ({ isOpen, setIsOpen }: Props) => {
  const [isVisible, showAnimation] = useAnimation(isOpen, 200);

  if (!isVisible && !showAnimation) return null;

  return (
    <MobileNavItemsContainer
      isVisible={isVisible}
      showAnimation={showAnimation}
    >
      <div className="dropdown-wrapper">
        <Dropdown isScrolled={false} isOpen handleToggleMenu={() => {}} />
      </div>

      <div className="list-wrapper">
        <NavMobileAccordion setIsOpen={setIsOpen} />
      </div>

      <div className="mob-sns-wrapper">
        <SnsComponent />
      </div>
    </MobileNavItemsContainer>
  );
};

export default MobileNavItems;

type MobileNavItemsContainerProps = {
  isVisible: boolean;
  showAnimation: boolean;
};

const MobileNavItemsContainer = styled.ul<MobileNavItemsContainerProps>`
  overflow-y: auto;
  position: fixed;
  z-index: 999;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background: ${MENUBG};
  animation: ${opacityAppear} 0.2s;
  ${({ showAnimation }) =>
    showAnimation &&
    css`
      animation: ${opacityDisappear} 0.2s;
    `};

  .dropdown-wrapper {
    padding: 0 32px;
    margin-top: 30px;
    margin-bottom: 54px;
  }
  .mob-sns-wrapper {
    padding: 0 48px;
    margin-top: 38px;
  }

  @media screen and (max-width: 1439px) {
    top: 64px;
  }
  @media screen and (max-width: 1239px) {
    top: 60px;
    .mob-sns-wrapper {
      padding: 0 48px;
    }
  }
  @media screen and (max-width: 600px) {
    top: 56px;
    .mob-sns-wrapper {
      padding: 0 24px;
    }
  }
  @media screen and (max-width: 360px) {
    .dropdown-wrapper {
      padding: 0 16px;
    }
    .mob-sns-wrapper {
      padding: 0 8px;
    }
  }
`;
