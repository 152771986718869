import { GRAY10 } from 'global-styles';
import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import MobileNavItems from './MobileNavItems';

type Props = {
  isScrolled: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MobileNavList = ({ isScrolled, setIsOpen, isOpen }: Props) => {
  const handleToggleIsOpen = () => setIsOpen(io => !io);

  return (
    <MobileNavListContainer>
      <HamBurgerBtn
        onClick={handleToggleIsOpen}
        isOpen={isOpen}
        isScrolled={isScrolled}
      >
        <span className="bar-top bar" />
        <span className="bar-bottom bar" />
      </HamBurgerBtn>

      <MobileNavItems
        isScrolled={isScrolled}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </MobileNavListContainer>
  );
};

export default MobileNavList;

interface HamBurgerBtnProps {
  isOpen: boolean;
  isScrolled: boolean;
}

const MobileNavListContainer = styled.div`
  display: none;
  @media screen and (max-width: 1048px) {
    display: block;
  }
`;

const HamBurgerBtn = styled.button<HamBurgerBtnProps>`
  width: 48px;
  height: 48px;
  position: relative;
  background: transparent;
  display: block;
  .bar-top {
    top: 34.04%;
    bottom: 59.57%;
    left: 6.25%;
  }
  .bar-bottom {
    left: 6.25%;
    bottom: 36.17%;
  }

  .bar {
    transition: all 0.2s;
    position: absolute;
    width: 42px;
    height: 3px;
    background: ${({ isScrolled }) => (isScrolled ? GRAY10 : '#fff')};
    transform-origin: center;
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      .bar-top {
        top: 50%;
        transform: rotate(45deg) translate3d(0, -50%, 0);
      }
      .bar-bottom {
        top: 50%;
        left: 10%;
        transform: rotate(-45deg) translate3d(0, -50%, 0);
      }
      .bar {
        background: #fff;
      }
    `};
  @media screen and (min-width: 1049px) {
    display: none;
  }
`;
