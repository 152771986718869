import { opacityAppear } from 'components/Home';
import {
  MenuIntroExplainKRText,
  MenuIntroText,
  ThumbnailBottomText,
} from 'global-styles';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

type Props = {
  menuIntroText: string;
  menuIntroExplainText: { ko: string; en: string };
  thumbnailURL?: string;
  thumbnailText?: string;
};

export const DetailAreaTemplate = ({
  menuIntroText,
  menuIntroExplainText,
  thumbnailURL,
  thumbnailText,
}: Props) => {
  const { locale } = useRouter();

  return (
    <ProductDetailAreaContainer>
      <MenuIntroText className="intro-text">{menuIntroText}</MenuIntroText>
      <MenuIntroExplainKRText className="explain-text">
        {/* 만족도를 높이는 시청 경험. */}
        {menuIntroExplainText[locale]}
      </MenuIntroExplainKRText>

      {thumbnailURL && (
        <div className="thumbnail-area">
          <Image
            src={thumbnailURL}
            alt=""
            layout="fixed"
            width="210"
            height="130"
            priority
            loading="eager"
          />
        </div>
      )}
      <ThumbnailBottomText className="thumbnail-text">
        {thumbnailText}
      </ThumbnailBottomText>
    </ProductDetailAreaContainer>
  );
};

const ProductDetailAreaContainer = styled.div`
  animation: ${opacityAppear} 0.5s;
  padding-top: 24px;

  .explain-text {
    display: block;
    margin-top: 4px;
    margin-left: 2px;
  }
  .thumbnail-area {
    width: 210px;
    height: 130px;
    margin: 32px 0 4px;
  }
  .thumbnail-text,
  .intro-text {
    margin-left: 2px;
  }
`;
