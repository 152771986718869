import { useEffect } from 'react';
import { DropdownProps, ToggleContextValue } from '../types';

type ListRenderingType = Pick<
  ToggleContextValue,
  'isOpen' | 'optionListRef' | 'toggleRef'
> & {
  container: HTMLDivElement | null;
  isPositionFixed: DropdownProps['isPositionFixed'];
};
// 옵션리스트

export const useSetOptionListRendering = ({
  isOpen,
  container,
  toggleRef,
  optionListRef,
  isPositionFixed,
}: ListRenderingType) => {
  const renderOptionList = () => {
    const toggleElement = toggleRef?.current;
    const optionListElement = optionListRef?.current;

    if (!toggleElement || !optionListElement) return;

    if (isOpen) {
      const { scrollY, scrollX } = window;
      const toggleBoundingRect = toggleElement.getBoundingClientRect();
      const topCoords = isPositionFixed
        ? toggleBoundingRect.bottom
        : toggleBoundingRect.bottom + scrollY;
      const leftCoords = isPositionFixed
        ? toggleBoundingRect.left
        : toggleBoundingRect.left + scrollX;

      if (isPositionFixed) optionListElement.style.position = 'fixed';
      optionListElement.style.minWidth = `${toggleBoundingRect.width}px`;
      optionListElement.style.left = `${leftCoords}px`;
      optionListElement.style.top = `${topCoords}px`;
      optionListElement.style.marginTop = '8px';

      optionListElement.style.transform = 'scale(1)';
      optionListElement.style.opacity = '1';
    }
  };

  useEffect(() => {
    if (container) renderOptionList();
  }, [container]);
};
