import { Dispatch, SetStateAction, useRef } from 'react';
import styled, { css } from 'styled-components';
import { openAnim } from 'components/Common/MainDropdown';
import { BB, GRAY50, GRAY80, GRAYBG, BodyTextM } from 'global-styles';
import { useCloseModalClickOutside } from '../../hooks/useCloseModalClickOutside';
import { DownArrowIcon } from 'assets/icons';

interface BasicDropdownProps {
  value: string;
  options: string[];
  onClickOptions: Dispatch<SetStateAction<string>>;
  width?: string;
  height?: string;
}

export const BasicDropdown = ({
  value,
  options,
  onClickOptions,
  width,
  height,
}: BasicDropdownProps) => {
  const modalRef = useRef();
  const [isOpen, setIsOpen] = useCloseModalClickOutside(modalRef);

  const handleToggleOpen = () => setIsOpen(isOpen => !isOpen);

  return (
    <BasicDropDownContainer
      width={width}
      height={height}
      isOpen={isOpen}
      onClick={e => {
        e.preventDefault();
        handleToggleOpen();
      }}
      ref={modalRef}
    >
      <button className="dd-tg-btn">
        <BodyTextM>{value}</BodyTextM>
        <DownArrowIcon color={GRAY50} />
      </button>
      {isOpen && (
        <ul className="option-list">
          {options.map(option => (
            <li
              key={option}
              onClick={() => onClickOptions(option)}
              className={value === option ? 'selected option' : 'option'}
            >
              <button className="dd-o-btn">
                <BodyTextM>{option}</BodyTextM>
              </button>
            </li>
          ))}
        </ul>
      )}
    </BasicDropDownContainer>
  );
};

export default BasicDropdown;

interface BasicDropDownContainerProps {
  width?: string;
  height?: string;
  isOpen: boolean;
}

const BasicDropDownContainer = styled.div`
  position: relative;
  width: ${({ width }: BasicDropDownContainerProps) => width || 'fit-content'};

  button {
    width: ${({ width }: BasicDropDownContainerProps) => width || '100%'};
    padding: 10px 16px;
    text-align: left;
    border-radius: 4px;
    background: #fff;
    color: ${GRAY50};
  }

  .option-list {
    padding: 8px 0;
    overflow: hidden;
    border-radius: 4px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 100%;
    width: ${({ width }: BasicDropDownContainerProps) => width || '100%'};
    border: 1px solid ${GRAY80};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    animation: ${openAnim} 0.1s ease-in-out;
  }

  .dd-o-btn {
    background: transparent;
    color: ${GRAY50};
  }
  .dd-o-btn:hover {
    color: ${BB};
    background: ${GRAYBG};
  }

  .selected {
    .dd-o-btn {
      color: ${BB};
    }
  }

  .dd-tg-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${GRAY50};
    color: ${BB};
    svg {
      ${({ isOpen }: BasicDropDownContainerProps) =>
        isOpen
          ? css`
              transform: rotate(180deg);
            `
          : css`
              transform: rotate(0deg);
            `};
    }
  }
`;
