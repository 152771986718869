import { AxiosResponse } from 'axios';
import { axiosEnInstance, axiosInstance } from 'axiosInstance';
import { OrderType } from 'context/BlogContext';
export type PostCategory = 'media trend' | 'news' | 'technology';
export type PostType =
  | 'deepfield-pqo'
  | 'deepfield-sr'
  | 'pulsar-av1'
  | 'pulsar-avif'
  | 'pulsar-vmaf';

export type GetPostParmas = {
  page_num: number;
  page_size: number;
  category: PostCategory | 'all';
  order_by: OrderType;
  order: 'ASC' | 'DESC';
  search: string | undefined;
};

export type BlogPostInfoRes = {
  id: number;
  author: string;
  date: string;
  category: PostCategory;
  title: string;
  content: string;
  excerpt: string;
  post_type: PostType;
  post_name: string;
  tags: string[];
  thumbnail: string;
};

export type PostListItem = {
  id: number;
  post_view_count: number;
  post_type: PostType;
  category: PostCategory;
  title: string;
  date: {
    date: string;
    timezone_type: number;
    timezome: string;
  };
  thumbnail: string;
};
interface GetPostListRes {
  total_post_count: number;
  total_page_count: number;
  curr_page_num: number;
  posts: PostListItem[];
}
interface RelatedPostItem {
  id: number;
  category: PostCategory;
  title: string;
  post_type: PostType;
  thumbnail: string;
}
interface GetRelatedPostListRes {
  total_post_num: number;
  posts: RelatedPostItem[];
}
type GetAllPostIdsRes = { total_post_count: number; post_ids: number[] };

export type SimplePostInfo = { id: number; title: string };
type GetTheTwoLatestPostInfoRes = { posts: SimplePostInfo[] };

type IncreasePostViewCountRes = number;

/**
 * @param params - post list 필터링을 위한 조건들
 * @param {boolean} useSearch - search를 사용하는지 아닌지 여부.
 * @returns
 */
export const getPostList = async (
  params: GetPostParmas,
  useSearch: boolean
): Promise<GetPostListRes> => {
  // search 사용시에만 search 문자열 param 객체에 포함
  const requestParams = {
    ...params,
    search: useSearch ? params.search : undefined,
  };
  try {
    const { data }: AxiosResponse<GetPostListRes> = await axiosInstance.get(
      '/posts',
      {
        params: requestParams,
      }
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getRelatedPostList = async (
  postId: number
): Promise<GetRelatedPostListRes> => {
  try {
    const { data }: AxiosResponse<GetRelatedPostListRes> =
      await axiosInstance.get(`/related-posts/${postId}`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getPostInformation = async (
  postId: number
): Promise<BlogPostInfoRes> => {
  try {
    const { data }: AxiosResponse<BlogPostInfoRes> = await axiosInstance.get(
      `/post/${postId}`,
      {
        headers: { 'Accept-Encoding': 'gzip,deflate,compress' }, // <br/> 인코딩 관련 이슈 해결위해서 설정
      }
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const increasePostViewCount = async (
  postId: number
): Promise<IncreasePostViewCountRes> => {
  try {
    const { data }: AxiosResponse<IncreasePostViewCountRes> =
      await axiosInstance.post(`/views/increase/${postId}`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getAllPostIds = async (): Promise<GetAllPostIdsRes> => {
  try {
    const { data }: AxiosResponse<GetAllPostIdsRes> = await axiosInstance.get(
      '/post-ids'
    );
    console.log(data);

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getTheTwoLatestPostInfo =
  async (): Promise<GetTheTwoLatestPostInfoRes> => {
    try {
      const { data }: AxiosResponse<GetTheTwoLatestPostInfoRes> =
        await axiosInstance.get('/latest-posts');

      return data;
    } catch (e) {
      console.log(e);
    }
  };

//

export const getENPostList = async (
  params: GetPostParmas,
  useSearch: boolean
): Promise<GetPostListRes> => {
  // search 사용시에만 search 문자열 param 객체에 포함
  const requestParams = {
    ...params,
    search: useSearch ? params.search : undefined,
  };
  try {
    const { data }: AxiosResponse<GetPostListRes> = await axiosEnInstance.get(
      '/posts',
      {
        params: requestParams,
      }
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getENRelatedPostList = async (
  postId: number
): Promise<GetRelatedPostListRes> => {
  try {
    const { data }: AxiosResponse<GetRelatedPostListRes> =
      await axiosEnInstance.get(`/related-posts/${postId}`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getENPostInformation = async (
  postId: number
): Promise<BlogPostInfoRes> => {
  try {
    const { data }: AxiosResponse<BlogPostInfoRes> = await axiosEnInstance.get(
      `/post/${postId}`,
      {
        headers: { 'Accept-Encoding': 'gzip,deflate,compress' }, // <br/> 인코딩 관련 이슈 해결위해서 설정
      }
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const increaseENPostViewCount = async (
  postId: number
): Promise<IncreasePostViewCountRes> => {
  try {
    const { data }: AxiosResponse<IncreasePostViewCountRes> =
      await axiosEnInstance.post(`/views/increase/${postId}`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getENAllPostIds = async (): Promise<GetAllPostIdsRes> => {
  try {
    const { data }: AxiosResponse<GetAllPostIdsRes> = await axiosEnInstance.get(
      '/post-ids'
    );
    console.log(data);

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getENTheTwoLatestPostInfo =
  async (): Promise<GetTheTwoLatestPostInfoRes> => {
    try {
      const { data }: AxiosResponse<GetTheTwoLatestPostInfoRes> =
        await axiosEnInstance.get('/latest-posts');

      return data;
    } catch (e) {
      console.log(e);
    }
  };

