import { LogoArea } from 'components/Header/LogoArea';
import { MENUBG } from 'global-styles';
import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import SubMenus from './\bSubMenus';
import DesktopNavList from './DesktopNavList';
// import HeaderPopupArea from './HeaderPopupArea';
import MobileNavList from './MobileNavList';

const HeaderPopupArea = dynamic(() => import('./HeaderPopupArea'), {
  ssr: false,
});

export type Submenus = 'company' | 'product' | null;
export type Products =
  | 'DeepField-SR1/SR2'
  | 'DeepField-SR3'
  | 'DeepField-PQO'
  | 'Pulsar-AV1'
  | 'Pulsar-AVIF'
  | 'Pulsar-VMAF';
type Props = {
  isScrolled: boolean;
};

const Index = ({ isScrolled }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submenuType, setSubmenuType] = useState<Submenus>(null);
  const [product, setProduct] = useState<Products>('DeepField-SR1/SR2');
  const layoutRef = useRef();

  // 전체 화면 크기 1048 이하일 경우 상태제어 hooks
  useEffect(() => {
    const mql = window.matchMedia('(max-width: 1048px)');
    const handleControlIsOpen = () => {
      setIsOpen(false);
    };

    // polyfill for safari old version
    const handleControlIsOpenDeprecated = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setIsOpen(false);
        console.log('matches');
      }
    };

    if (mql?.addEventListener)
      mql.addEventListener('change', handleControlIsOpen);
    else if (mql?.addListener) mql.addListener(handleControlIsOpenDeprecated);

    return () => {
      if (mql?.removeEventListener)
        mql.removeEventListener('change', handleControlIsOpen);
      else if (mql?.removeListener)
        mql.removeListener(handleControlIsOpenDeprecated);
    };
  }, []);

  useEffect(() => {
    if (isOpen) document.body.style.overflowY = 'hidden';
    else document.body.style.overflowY = 'unset';
  }, [isOpen]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <NewHeaderContainer isScrolled={isScrolled} isOpen={isOpen} ref={layoutRef}>
      {/* logo */}
      <LogoArea isScrolled={isScrolled} isOpen={isOpen} />

      {/* desktop / mobile 메뉴 */}
      <DesktopNavList
        isScrolled={isScrolled}
        setIsOpen={setIsOpen}
        setSubmenuType={setSubmenuType}
      />
      <MobileNavList
        isScrolled={isScrolled}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <SubMenus
        submenuType={submenuType}
        setSubmenuType={setSubmenuType}
        setProduct={setProduct}
        product={product}
      />

      <HeaderPopupArea />
    </NewHeaderContainer>
  );
};

export default Index;

type NewHeaderContainerProps = {
  isScrolled: boolean;
  isOpen: boolean;
};


const NewHeaderContainer = styled.header<NewHeaderContainerProps>`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  transition: 0.2s all;

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      background: #fff;
      box-shadow: 5px 1px 10px 1px rgba(95, 95, 95, 0.25);
    `};

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${MENUBG};
    `};

  @media screen and (max-width: 1439px) {
    height: 64px;
  }
  @media screen and (max-width: 1239px) {
    height: 60px;
  }
  @media screen and (max-width: 599px) {
    height: 56px;
    padding: 0 16px;
  }
`;
