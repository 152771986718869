import { txtAnimation } from 'components/Home/BannerItems';
import { gridSystemPadding } from 'components/Layout';
import styled from 'styled-components';

/**
 * Next/Image 컴포넌트를 감싸는 역할을 한다. (position 기준)
 * @param {boolean} isNormalUse - 배경으로 사용하는지 아닌지 여부
 */
export const ImgBackgroundWrapper = styled.div<{ isNormalUse?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ isNormalUse }) => (isNormalUse ? 0 : -1)};
  .target-img {
    width: 100%;
    height: 100%;
  }
`;

export const PageOuterContainer = styled.div`
  position: relative;
  overflow-x: hidden;
`;

export const MainVisualOuterContainer = styled.section`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100vw;
  width: 100%;
  height: calc(var(--vh, 1vh) * 75);
  color: #fff;
  background: linear-gradient(
    0deg,
    rgba(21, 35, 70, 0.9),
    rgba(21, 35, 70, 0.9)
  );

  @media screen and (max-width: 904px) {
    height: calc(var(--vh, 1vh) * 100);
  }
`;

export const MainVisualInnerContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${gridSystemPadding}
  .main-typo {
    color: #fff;
    animation: ${txtAnimation} 1.5s 0.3s cubic-bezier(0.4, 0, 0.2, 1) backwards;
    margin-bottom: 16px;
  }
  .main-typo span {
    color: #2564ce;
  }
  .en-wrapper-pc {
    display: block;
  }
  .en-wrapper-mo {
    display: none;
  }
  .desc-text-wrapper {
    overflow-y: hidden;
  }
  .desc-text-wrapper .anim-wrapper {
    animation: ${txtAnimation} 1.5s 0.8s cubic-bezier(0.4, 0, 0.2, 1) backwards;
  }

  @media screen and (max-width: 1239px) {
    .main-typo {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 599px) {
    .en-wrapper-pc {
      display: none;
    }
    .en-wrapper-mo {
      display: block;
    }
  }
`;
