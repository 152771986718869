import { GRAY70, GRAYBG, ButtonText2 } from 'global-styles';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

export type LNBItemType = {
  label: string;
  link: string;
};
interface Props {
  items: LNBItemType[];
  pathname: string;
  locale: string;
  isMobileSize: boolean;
  isOpen: boolean;
}

export const BasicLNB = ({ items, isMobileSize, isOpen, locale }: Props) => {
  const { asPath } = useRouter();

  return (
    <BasicLNBContainer isMobileSize={isMobileSize} isOpen={isOpen}>
      {items.map(item => (
        <Link href={item.link} locale={locale} key={item.label} scroll>
          <a
            tabIndex={isOpen ? 0 : -1}
            className={
              asPath
                .toLowerCase()
                .includes(item.label.split(' : ')[0].toLowerCase())
                ? 'item-anchor selected'
                : 'item-anchor'
            }
          >
            <ButtonText2 className="btn-text2">{item.label}</ButtonText2>
          </a>
        </Link>
      ))}
    </BasicLNBContainer>
  );
};

interface BasicLNBContainerProps {
  isMobileSize: boolean;
  isOpen: boolean;
}

const BasicLNBContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.3s padding cubic-bezier(0.1, 0.57, 0.1, 1);
  min-width: 144px;

  .item-anchor {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${GRAY70};
    opacity: ${({ isMobileSize, isOpen }: BasicLNBContainerProps) =>
      (isOpen && isMobileSize) || !isMobileSize ? 1 : 0};
    height: ${({ isMobileSize, isOpen }: BasicLNBContainerProps) =>
      (isOpen && isMobileSize) || !isMobileSize ? '36px' : 0};
    transform: ${({ isMobileSize, isOpen }: BasicLNBContainerProps) =>
      (isOpen && isMobileSize) || !isMobileSize ? 'scaleY(1)' : 'scaleY(0)'};
    transition: 0.3s all cubic-bezier(0.1, 0.57, 0.1, 1);
  }
  .item-anchor:hover,
  .item-anchor.selected {
    color: ${GRAYBG};
  }
  ${({ isMobileSize, isOpen }: BasicLNBContainerProps) =>
    !isMobileSize
      ? css`
          display: ${isOpen ? 'unset' : 'none'};
          position: absolute;
          width: fit-content;
          white-space: nowrap;
          top: calc(100% + 6px);
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          padding: 16px;
          background: rgba(0, 0, 0, 0.75);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 16px;
          justify-content: center;
          align-items: center;
        `
      : css`
          transition: max-height 0.3s;
          max-height: ${isOpen ? '188px' : '0'};
          opacity: ${isOpen ? 1 : 0};
          padding-left: 32px;
          position: relative;
          z-index: 9999;

          .item-anchor {
            justify-content: flex-start;
            font-family: 'Noto Sans KR', sans-serif;
            opacity: ${isOpen ? 1 : 0};
            font-size: 2.8rem;
            line-height: 175%;
            letter-spacing: -0.01rem;
            height: 36px;

            .btn-text2 {
              @media screen and (max-width: 1239px) and (min-width: 905px) {
                font-size: 22px;
              }
              @media screen and (max-width: 904px) and (min-width: 600px) {
                font-size: 18px;
              }
              @media screen and (max-width: 599px) {
                font-size: 17px;
              }
            }
          }
        `};
`;
