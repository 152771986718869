import { BB } from 'global-styles';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { ContactBtnIcon } from 'assets/icons';

interface Props {
  onClick: () => void;
}

export const ContactButton = ({ onClick }: Props) => {
  const { locale } = useRouter();
  const text = locale === 'en' ? 'Contact' : '문의 연락';
  const ariaLabel =
    locale === 'en' ? 'Open the Sales contact pop up.' : '문의하기 팝업창 열기';

  return (
    <ContactButtonContainer
      onClick={onClick}
      id="contact-popup-btn"
      aria-haspopup
      aria-label={ariaLabel}
    >
      <span className="hover-text">{text}</span>
      <ContactBtnIcon />
    </ContactButtonContainer>
  );
};

const ContactButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  color: ${BB};
  font-size: 16px;
  font-weight: 500;
  font-family: 'Noto Sans KR', sans-serif;
  white-space: nowrap;
  transition: all 0.3s;

  .hover-text {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      width: fit-content;
      padding: 0px 32px;
      svg {
        margin-left: 9px;
      }
      .hover-text {
        display: unset;
      }
    }
  }

  @media screen and (max-width: 904px) and (min-width: 600px) {
    width: 45px;
    height: 45px;
  }
  @media screen and (max-width: 599px) {
    width: 45px;
    height: 45px;
  }
`;
