import { NormalArrowIcon } from 'assets/icons';
import { opacityAppear } from 'components/Home';
import {
  ALLSTROKE,
  HASHTAG,
  MenuExplainENText,
  MenuExplainKRText,
  MenuNameText,
  ProductHeaderText,
  PRODUCTSUB,
} from 'global-styles';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  cloneElement,
  Dispatch,
  Fragment,
  SetStateAction,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { Products } from '.';
import { FakeItemComponent } from './CustomComponents';

export type ItemProps = {
  itemName: string;
  itemBrief: { en: JSX.Element[]; ko: JSX.Element[] };
  hashItems: { en: string[]; ko: string[] };
  href: string;
  imgSrc?: string;
};

type Props = {
  items: ItemProps[];
  topText: { left: string; right: string };
  setProduct: Dispatch<SetStateAction<Products>>;
};

export const SubMenuAreaProductTemplate = ({
  items,
  topText,
  setProduct,
}: Props) => {
  const { locale } = useRouter();

  return (
    <SubMenuAreaTemplateContainer>
      <div className="t-a">
        <ProductHeaderText className="t-a-t">{topText.left}</ProductHeaderText>
        <ProductHeaderText className="t-a-t">{topText.right}</ProductHeaderText>
      </div>

      <div className="grid-area">
        {items.map((item, i) => (
          <Link key={i} href={item.href}>
            <a
              className="grid-item-wrapper"
              style={{ color: '#fff' }}
              onMouseOver={() => setProduct(item.itemName as Products)}
            >
              <div className="grid-item">
                <MenuNameText className="p-item-name">
                  {item.itemName}
                </MenuNameText>

                <div className="p-brief-wrapper">
                  {item.itemBrief[locale]?.map(brief => (
                    <Fragment key={brief[locale]}>{brief}</Fragment>
                  )) || ''}
                </div>

                <div className="hash-wrapper">
                  {item.hashItems[locale].map(hashItem =>
                    locale === 'en' ? (
                      <MenuExplainENText className="hash-item">
                        {hashItem}
                      </MenuExplainENText>
                    ) : (
                      <MenuExplainKRText className="hash-item">
                        {hashItem}
                      </MenuExplainKRText>
                    )
                  )}
                </div>

                <div className="goto-btn">
                  <NormalArrowIcon color="#fff" />
                </div>
              </div>
            </a>
          </Link>
        ))}
      </div>

      <div className="b-a">
        <span />
        <span />
      </div>
    </SubMenuAreaTemplateContainer>
  );
};

const SubMenuAreaTemplateContainer = styled.div`
  animation: ${opacityAppear} 0.25s ease-in;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior-y: none;
  .grid-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100% - 76px);
    grid-auto-rows: 184px;
  }
  .grid-item-wrapper {
    display: block;
    border-bottom: 1px solid ${ALLSTROKE};
    border-right: 1px solid ${ALLSTROKE};
  }
  .grid-item {
    position: relative;
    cursor: pointer;
    border: 2px solid transparent;
    padding: 20px; // border 2px 2px씩 값 감안해서 24 -> 20px로 설정
    transition: border 0.1s ease-in;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .grid-item-wrapper:hover .grid-item {
    border: 2px solid #fff;
  }
  .grid-item-wrapper:hover .goto-btn {
    opacity: 1;
  }
  .grid-item-wrapper:hover .p-item-name {
    text-decoration: underline;
  }
  .p-item-name {
    font-size: 20px;
  }
  .p-item-brief {
    color: ${PRODUCTSUB};
  }
  .hash-wrapper {
    color: ${HASHTAG};
  }
  .hash-item + .hash-item {
    margin-left: 8px;
  }
  .goto-btn {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 20px;
    right: 20px;
    background: rgba(0, 66, 142, 0.28);
    border: 2.5px solid #ffffff;
    border-radius: 10px 0px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s ease-in;
  }
  .t-a,
  .b-a {
    width: 100%;
  }
  .t-a {
    justify-content: space-between;
    display: flex;
    height: 48px;
    padding: 0 0 8px 0;
    border-bottom: 1px solid ${ALLSTROKE};
  }
  .t-a > .t-a-t:first-child {
    border-right: 1px solid ${ALLSTROKE};
  }
  .t-a-t {
    width: 100%;
    height: 48px;
    padding: 16px 0 0 24px;
    border-bottom: 1px solid ${ALLSTROKE};
  }
  .b-a {
    display: flex;
    height: 28px;
  }
  .b-a > span {
    width: 100%;
  }
  .b-a > span:nth-of-type(2) {
    border-left: 1px solid ${ALLSTROKE};
  }
`;

export type ItemFeatureType = 'link' | 'custom' | 'fake';

export type CompanyProps = {
  itemType: ItemFeatureType;
  itemName: string;
  customComponent?: JSX.Element;
  href?: string;
  itemBrief: { en: JSX.Element[]; ko: JSX.Element[] };
};

type CompanySubMenuAreaProps = { items: CompanyProps[] };

export const CompanySubMenuAreaProductTemplate = ({
  items,
}: CompanySubMenuAreaProps) => {
  // const { locale } = useRouter();
  const addedItem = useMemo(() => {
    const filteredItems = items;

    if (filteredItems.length < 6) {
      const { length } = filteredItems;
      const sub = 6 - length;

      const placeholderItems = Array.from(
        { length: sub },
        (_, i: number) =>
          ({
            itemType: 'fake',
            itemName: `fake-${i}`,
            customComponent: <FakeItemComponent key={`fake-${i}`} />,
            itemBrief: {
              en: [],
              ko: [],
            },
          } as CompanyProps)
      );
      return [...filteredItems, ...placeholderItems];
    }
    return filteredItems;
  }, [items]);

  return (
    <CompanySubMenuAreaTemplateContainer>
      <div className="t-a">
        <ProductHeaderText className="t-a-t" />
        <ProductHeaderText className="t-a-t" />
      </div>

      <div className="grid-area">
        {addedItem?.map(item =>
          item.itemType === 'link' ? (
            <LinkComponentTemplate item={item} key={item.itemName} />
          ) : (
            <>{cloneElement(item.customComponent, { key: item.itemName })}</>
          )
        )}
      </div>

      <div className="b-a">
        <span />
        <span />
      </div>
    </CompanySubMenuAreaTemplateContainer>
  );
};

const CompanySubMenuAreaTemplateContainer = styled(
  SubMenuAreaTemplateContainer
)`
  .t-a-t {
    width: 100%;
    padding: 0;
  }
  .grid-item {
    justify-content: flex-start;
  }
  .p-brief-wrapper {
    margin-top: 24px;
  }
  .no-border {
    border: 2px solid transparent !important;
    cursor: default;
  }
`;

type LinkComponentTemplateProps = {
  item: CompanyProps;
};

export const LinkComponentTemplate = ({ item }: LinkComponentTemplateProps) => {
  const { locale } = useRouter();

  return (
    <>
      <Link href={item.href || ''}>
        <a className="grid-item-wrapper" style={{ color: '#fff' }}>
          <div className={item.itemName ? 'grid-item' : 'grid-item no-border'}>
            {item.itemName && (
              <MenuNameText className="p-item-name">
                {item.itemName}
              </MenuNameText>
            )}

            {item.itemBrief && (
              <div className="p-brief-wrapper">
                {item.itemBrief[locale]?.map(brief => brief) || ''}
              </div>
            )}

            {item.itemName && (
              <div className="goto-btn">
                <NormalArrowIcon color="#fff" />
              </div>
            )}
          </div>
        </a>
      </Link>
    </>
  );
};
