import { BB, BBBright, GRAY30, GRAY60, GRAY70 } from 'global-styles';
import styled, { css } from 'styled-components';

export const ToggleContainer = styled.button<{
  changeStyle: boolean;
  displayBorder: boolean;
}>`
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s all;
  color: #fff;
  border: 1px solid #fff;
  svg {
    path {
      fill: #fff;
    }
  }

  svg {
    margin-left: 8px;
  }
  ${({ changeStyle }) =>
    changeStyle &&
    css`
      color: ${BB};
      border: 1px solid #ccc;
      svg {
        path {
          fill: ${BB};
        }
      }
    `};

  ${({ displayBorder }) =>
    !displayBorder &&
    css`
      padding: 8px 6px;
      border: 1px solid transparent;
      color: ${GRAY30};
      svg {
        path {
          fill: ${GRAY60};
        }
      }
    `};
`;

export const OptionListContainer = styled.ul`
  font-size: 14px;
  padding: 10px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  opacity: 0;
  transform: scale(0);
  transform-origin: top center;
  transition: transform 0.1s ease-in-out, opacity 0.15s ease-in-out;
  z-index: 9999;
`;

export const OptionItemContainer = styled.li`
  & > * {
    display: inline-block;
    width: 100%;
    padding: 8px 0;
    text-align: center;
    color: ${GRAY70};
    text-transform: uppercase;
    cursor: pointer;
  }
  &[aria-selected='true'] > * {
    color: ${BB};
    text-decoration: underline;
    text-underline-position: under;
  }
  &[aria-disabled='true'] > * {
    cursor: not-allowed;
  }
  &:hover > button {
    color: ${GRAY30} !important;
  }
`;
