export const en = {
  midDesc: `< BLUEDOT > ("https://www.blue-dot.io/"hereinafter referred to as 'BLUEDOT') establishes and
discloses the privacy policy as follows to protect personal information and swiftly and efficiently
respond to related concerns pursuant to 「Personal Information Protection Act」 Article 30.`,
  articles: [
    {
      title: 'Article 1 (Purpose of Processing Personal Information)',
      desc: `The purposes of processing personal information files, < BLUEDOT > registers and discloses
pursuant to the Personal Information Protection Act Article 32, are as follows.

1. Personal Information File Name: BLUEDOT Privacy Policy
The Purpose of Processing Personal Information: Collection of basic information for service delivery and response to inquiries and suggestions
Means of Collection: homepage
Basis of Storage: Prevention of duplicate accounts and Act on the Consumer Protection in Electronic Commerce Act
Storage Period: 3 years
Related Laws: Recording of credit information collection/process and usage, etc.: 3 years, Recording of processing consumers' complaints and disputes: 3 years, Recording of payment and provision of commodities, etc.: 5 years, Recording of contract or withdrawal, etc.: 5 years, Recording of display/advertisement: 6 months`,
    },
    {
      title: 'Article 2 (Categories of Processed Personal Information)',
      desc: `① < BLUEDOT > processes the following personal information categories.
1. < BLUEDOT Privacy Policy >
Required: Email, mobile number, office telephone number, company name, service usage history,
access log, cookie, connecting IP information, payment history`,
    },
    {
      title:
        'Article 3 (Process and Method of Destroying Personal Information)',
      desc: `① < BLUEDOT > destroys the personal information without hesitation in case personal information
becomes unnecessary due to elapsed storage period, accomplishment of purpose, etc.
② In case personal information is required to be retained pursuant to other laws despite the elapse of storage period and accomplishment of the purpose of personal information approved by the information controller, the information is moved to a separate database (DB) or stored in a different storage location.
③ The process of destroying personal information is as follows.
1. Destruction Process
< BLUEDOT > selects personal information for which the reason for destruction occurred, and
destroys it with the approval of < BLUEDOT >'s privacy protection manager.
2. Destruction Method
Information in form of electronic file format is destroyed by the technical method whose records
cannot be restored.`,
    },
    {
      title:
        'Article 4 (Matters Concerning the Rights and Obligations of Information Controllers and their Legal Representatives and How to Exercise Them)',
      desc: `① Information controllers may exercise their rights to browse, correct, delete, request suspension of the process, etc. to BLUEDOT at any time.
② The rights in alignment with Clause 1 may be exercised via letter, phone call, email, FAX, etc. and the company shall take measures immediately.
③ The rights in alignment with Clause 1 may be exercised via an agent such as a legal representative of the information controller or a delegated person. In this case, the power of attorney must be submitted in the format prescribed by “Notification Concerning Method of Processing Personal Information (2020-7)” Annexation 11.
④ The rights of information controllers to request to browse or suspend personal information may be limited in pursuance of 「Personal Information Protection Act」 Article 35-7 and Article 37-2.
⑤ Regarding the request to correct or delete personal information, it shall not be eligible for deletion request in case the information is stipulated as the subject of storage in other laws.
⑥ In case of a request to browse, or request to correct or delete in pursuance of the information controller's rights, BLUEDOT verifies whether the person making the request is the information controller or their authorized representative.`,
    },
    {
      title:
        'Article 5 (Matters Concerning Securing Safety of Personal Information)',
      desc: `< BLUEDOT > is taking measures to secure safety of personal information as follow.
1. Conduction of regular internal audits
We conduct internal audits (quarterly) to secure the safety of personal information processing.
2. Minimizing and training employees dealing with personal information We implement measures to manage personal information by designating employees responsible to deal with personal information and minimizing the number of them.
3. Establishment and Implementation of the internal management plan We establish and implement an internal management plan to safely process personal information
4. Technical countermeasures to prepare for hacking, etc.
<BLUEDOT>('BLUEDOT') installs security programs, regularly renews and inspects them, builds the system in the restricted area from the outside and technically/physically surveils and blocks the threats to prevent leak and damage to personal information by hacking or virus.
5. Restriction of access to personal information
We take necessary measures to restrict access to personal information through authorization, change, and termination of access to personal information database system, and control unauthorized access from the outside by using a firewall system.`,
    },
    {
      title:
        'Article 6 (Matters Concerning Installation & Operation of Device to Automatically Collect Personal Information and Rejection)',
      desc: `① BLUEDOT utilizes 'cookie', which stores and from time to time retrieves usage information to provide users with customized services.
② Cookie is a small portion of information the server (http) used to operate the website sends to the user's computer, and it may be saved on the hard disk of user's PC.
A. The purpose of cookie: Cookies are used to provide users with optimized information by identifying the services the user visited, the pattern of website visits and usage, secure access, etc.
B. Install•Operation and Rejection of cookie: You may reject the storage of cookies through Tools at the top of your web browser>Internet Option >the settings in the Privacy menu.
C. In case you reject cookie storage, difficulties in using customized services may occur.`,
    },
    {
      title: 'Article 7 (Matters Concerning the Privacy Protection Manager)',
      desc: `① BLUEDOT designated the privacy protection manager as follows to take full responsibility for the tasks concerning personal information, deal with complaints of information controller, compensate their damages, etc.
      ▶ Privacy Protection Manager
Name: Hyeong-Seok Han
Position: CMO
Rank: Director
Contact: 02-6205-0812, philip.han@blue-dot.io
※ The contact is connected to Privacy Protection Division.

▶ Division in Charge of Privacy Protection
Division Name: Marketing
Manager: Hyeong-Seok Han
Contact: 02-6205-0812, philip.han@blue-dot.io

② Information controller may consult with the Privacy Protection Manager or Privacy Protection Division over the matters concerning inquiries, complaints, and compensations that occurred during using BLUEDOT's services (or business). BLUEDOT shall respond to and process the information controllers' inquiries promptly.`,
    },
    {
      title:
        'Article 8 (Division to Receive and Process Request to Inspect Personal Information)',
      desc: `The information controller may make a request to inspect personal information pursuant to ｢Personal Information Protection Act｣ Article 35 to the following division.

      < BLUEDOT > shall do our best to speedily process the information controller's request to inspect
personal information.

▶ Division in Charge of Receiving · Processing Personal Information Inspection Request
Division Name: Marketing
Manager: Ga-Eun Park
Contact: 02-6205-0812, gwen.park@blue-dot.io`,
    },
    {
      title:
        'Article 9 (Remedies for Infringement of Rights and Benefits of Information Controller)',
      desc: `Information controller may apply for dispute mediation, consultation, etc. to Personal Information Dispute Mediation Committee, KISA Personal Information Infringement Report Center, etc. to receive remedies for infringement of their personal information. Please consult with the following institutions over the report and consultation over other personal information infringement.
      1. Personal Information Dispute Mediation Committee: (without exchange number) 1833-6972 (www.kopico.go.kr)
      2. Personal Information Infringement Report Center: (without exchange number) 118 (privacy.kisa.or.kr)
      3. Supreme Prosecutor's Office: (without exchange number) 1301 (www.spo.go.kr)
      4. National Police Agency: (without exchange number) 182 (ecrm.cyber.go.kr)

The person whose rights and benefits are infringed due to a disposition or omission of the head of the government office regarding the request under 「Personal Information Protection Act」 Article 35 (Access to Personal Information), Article 36 (Rectification or Erasure of Personal Information), and Article 37 (Suspension of Processing of Personal Information) may file an administrative appeal in pursuance of Administrative Appeals Act.

※ Please refer to the web page of Online Administrative Appeals (www.simpan.go.kr) for further information on administrative appeals.`,
    },
    {
      title: 'Article 10 (Changes to Privacy Policy)',
      desc: `① The privacy policy above is active starting from September 07, 2022.`,
    },
  ],
};

export const ko = {
  midDesc:
    '< BLUEDOT >("https://www.blue-dot.io/"이하 "BLUEDOT")은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.',
  articles: [
    {
      title: '제1조(개인정보의 처리목적)',
      desc: `< BLUEDOT >(이)가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은
다음과 같습니다.

1. 개인정보 파일명 : BLUEDOT 개인정보처리방침
개인정보의 처리목적 : 서비스 전달, 문의 및 제안사항 회신을 기본 정보 수집
수집방법 : 홈페이지
보유근거 : 중복 신청 방지 및 전자상거래 등에서의 소비자보호에 관한 법률
보유기간 : 3년
관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년, 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년, 대금결제 및 재화 등의 공급에 관한 기록 : 5년, 계약 또는 청약철회 등에 관한 기록 : 5년, 표시/광고에 관한 기록 : 6개월`,
    },
    {
      title: '제2조(처리하는 개인정보의 항목)',
      desc: `① < BLUEDOT >은(는) 다음의 개인정보 항목을 처리하고 있습니다.
1. < BLUEDOT 개인정보처리방침 >
필수항목 : 이메일, 휴대전화번호, 회사전화번호, 회사명, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록`,
    },
    {
      title: '제3조(개인정보의 파기절차 및 파기방법)',
      desc: `① < BLUEDOT > 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차
< BLUEDOT > 은(는) 파기 사유가 발생한 개인정보를 선정하고, < BLUEDOT > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2. 파기방법
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다`,
    },
    {
      title:
        '제4조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)',
      desc: `① 정보주체는 BLUEDOT에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.

② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
⑥ BLUEDOT은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.`,
    },
    {
      title: '제5조(개인정보의 안전성 확보조치에 관한 사항)',
      desc: `< BLUEDOT >은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
1. 정기적인 자체 감사 실시
개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
2. 개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
3. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
4. 해킹 등에 대비한 기술적 대책
<BLUEDOT>('BLUEDOT')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
5. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.`,
    },
    {
      title:
        '제6조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)',
      desc: `① BLUEDOT 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`,
    },
    {
      title: '제7조 (개인정보 보호책임자에 관한 사항)',
      desc: `① BLUEDOT 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

      ▶ 개인정보 보호책임자
성명 :한형석
직책 :CMO
직급 :이사
연락처 :02-6205-0812, philip.han@blue-dot.io
※ 개인정보 보호 담당부서로 연결됩니다.
▶ 개인정보 보호 담당부서
부서명 :마케팅
담당자 :한형석
연락처 :02-6205-0812, philip.han@blue-dot.io
② 정보주체께서는 BLUEDOT 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. BLUEDOT 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.`,
    },
    {
      title: '제8조(개인정보의 열람청구를 접수·처리하는 부서)',
      desc: `정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
< BLUEDOT >은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
▶ 개인정보 열람청구 접수·처리 부서

부서명 : 마케팅
담당자 : 박가은
연락처 : 02-6205-0812, gwen.park@blue-dot.io`,
    },
    {
      title: '제9조(정보주체의 권익침해에 대한 구제방법)',
      desc: `정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)

「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.`,
    },
    {
      title: '제10조(개인정보 처리방침 변경)',
      desc: `① 이 개인정보처리방침은 2022년 09월 07부터 적용됩니다.`,
    },
  ],
};
