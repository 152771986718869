import { BB } from 'global-styles';
import { useRouter } from 'next/router';
import React from 'react';
import styled, { css } from 'styled-components';
import { ScrollBtnIcon } from 'assets/icons';

interface Props {
  isOpenContact: boolean;
  isScrolled: boolean;
  onClick: () => void;
}

export const ScrollButton = ({ isOpenContact, isScrolled, onClick }: Props) => {
  const { locale } = useRouter();
  const ariaLabels =
    locale === 'en'
      ? ['scroll to the top', 'scroll to bottom by window height']
      : ['최상단으로 스크롤 올리기', '인터넷 창 높이만큼 스크롤 내리기'];

  if (isOpenContact) return null;

  return (
    <ScrollButtonContainer
      aria-label={isScrolled ? ariaLabels[0] : ariaLabels[1]}
      className="scroll-btn"
      isScrolled={isScrolled}
      onClick={onClick}
    >
      <ScrollBtnIcon />
    </ScrollButtonContainer>
  );
};

interface ScrollButtonContainerProps {
  isScrolled: boolean;
}

export const ScrollButtonContainer = styled.button`
  width: 60px;
  height: 60px;
  margin-bottom: 16px;
  background: ${BB};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: 0.3s all;
  &:hover {
    background: ${BB};
  }

  @media screen and (max-width: 904px) and (min-width: 600px) {
    width: 45px;
    height: 45px;
  }
  @media screen and (max-width: 599px) {
    width: 45px;
    height: 45px;
  }

  svg {
    transition: 0.2s all ease-in-out;
    ${({ isScrolled }: ScrollButtonContainerProps) =>
      !isScrolled &&
      css`
        transform: rotate(180deg);
      `};
  }
`;
