import styled from 'styled-components';
import Slider from 'react-slick';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MainBannerArrowIcon } from 'assets/icons';
import { ButtonText1 } from 'global-styles';
import {
  SecondDescBanner,
  FirstDescBanner,
  FirstTitleBanner,
  SecondTitleBanner,
} from './BannerItems';
import { BannerSlideTemplate } from './index';
import mainBannerImg2 from 'public/asset/01-main-banner/contents2/mainvisual-img2.png';
import mainVideoL from 'public/asset/01-main-banner/contents1/mainvisual-video-L.mp4';
import mainVideoM from 'public/asset/01-main-banner/contents1/mainvisual-video-M.mp4';
import mainVideoS from 'public/asset/01-main-banner/contents1/mainvisual-video-S.mp4';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  $mainBannerRef: (node?: Element) => void;
}

export const MainBanner = ({ $mainBannerRef }: Props) => {
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [slideIdx, setSlideIdx] = useState({
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
  });
  const slickRef = useRef<Slider>(null);
  const settings = useMemo(
    () => ({
      infinite: true,
      speed: 700,
      useTransform: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      Infinity: true,
      arrows: false,
      pauseOnHover: false,
      easing: 'ease-in-out',
      autoplay: false,
      autoplaySpeed: 7000,
      beforeChange: (current, next) =>
        setSlideIdx({ ...slideIdx, oldSlide: current, activeSlide: next }),
      afterChange: current =>
        setSlideIdx({ ...slideIdx, activeSlide2: current }),
    }),
    [slideIdx]
  );

  const handleClickNext = () => {
    if (slickRef.current) slickRef.current.slickNext();
  };

  const handleClickPrev = () => {
    if (slickRef.current) slickRef.current.slickPrev();
  };

  useEffect(() => {
    setVideoUrl(
      isDesktop ? mainVideoL : isMobileOnly ? mainVideoS : mainVideoM
    );
  }, []);

  return (
    <MainBannerContainer
      className="main-section-container"
      ref={$mainBannerRef}
    >
      <StyledSlider {...settings} ref={slickRef}>
        <div>
          <BannerSlideTemplate
            useVideo
            MainComponent={FirstTitleBanner}
            DescComponent={FirstDescBanner}
            backgroundImagePath={videoUrl}
            blurImgPath={''}
            movePath="/"
          />
        </div>
        <div>
          <BannerSlideTemplate
            useVideo={false}
            MainComponent={SecondTitleBanner}
            DescComponent={SecondDescBanner}
            backgroundImagePath={mainBannerImg2.src}
            movePath="/"
          />
        </div>
      </StyledSlider>

      <div className="buttons-wrapper">
        <button
          onClick={handleClickPrev}
          className="cus-slick-prev cus-slick-button"
          aria-label="previous"
        >
          <MainBannerArrowIcon />
        </button>
        <SlickIndicatorBtnText>
          <span>{slideIdx.activeSlide2 + 1}</span>
          <span className="slash" />
          <span>2</span>
        </SlickIndicatorBtnText>

        <button
          aria-label="next"
          onClick={handleClickNext}
          className="cus-slick-next cus-slick-button"
        >
          <MainBannerArrowIcon />
        </button>
      </div>

      {/* <AvifBanner /> */}
    </MainBannerContainer>
  );
};

const StyledSlider = styled(Slider)`
  overflow-x: hidden;
  width: 100vw;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  .slick-initialized {
    overflow: hidden;
  }
`;

const MainBannerContainer = styled.main`
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  width: 100%;
  min-height: 640px;
  position: relative;
  z-index: 99;
  overscroll-behavior-y: none;
  .buttons-wrapper {
    position: absolute;
    left: 32px;
    bottom: 32px;
    display: flex;
    align-items: center;
    .cus-slick-button {
      width: 48px;
      height: 48px;
      justify-content: center;
      background: transparent;
      display: flex;
      align-items: center;
      color: #fff;
    }
    .cus-slick-next {
      transform: rotate(180deg);
    }
  }
  .banner-img {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  @media screen and (max-width: 599px) {
    .buttons-wrapper {
      left: 16px;
      bottom: 16px;
    }
  }
`;

const SlickIndicatorBtnText = styled(ButtonText1)`
  color: #fff;
  font-size: 15px;
  width: 76px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .slash {
    transform: rotate(35deg);
    display: inline-block;
    width: 1px;
    height: 16px;
    background: #fff;
  }
`;
