import { MainButton } from 'global-styles/MainButton';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { GoToIcon } from 'assets/icons';
import { generateCssStyle, mediaQueryStyle } from '../../styles/breakPoints';
import { BBINDIGO, H2KR } from 'global-styles';

export const CareerBanner = () => {
  const { locale } = useRouter();

  if (locale === 'en') return null;
  return (
    <CareerBannerContainer>
      <div className="inner-container">
        <H2KR>블루닷과 함께 성장해 나갈</H2KR>
        <H2KR>열정 가득한 인재를 찾고 있습니다.</H2KR>

        <MainButton
          injectInlineStyles={{ width: '328px' }}
          injectCssStyles={[
            generateCssStyle({ 'margin-top': '32px', height: '48px' }),
            mediaQueryStyle.M`margin-top:56px; height: 56px;`,
          ]}
          icon={<GoToIcon />}
          tag="a"
          href="https://blue-dot.career.greetinghr.com/"
          buttonType="default-w"
          changeIconColor
          target="_blank"
          iconPosition="right"
          text="채용페이지 바로가기"
        />
      </div>
    </CareerBannerContainer>
  );
};

const CareerBannerContainer = styled.section`
  background: ${BBINDIGO};
  .inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    padding: 93.5px 0;
    text-align: center;
    @media screen and (min-width: 1440px) {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 93.5px 0;
    }
    @media screen and (min-width: 1240px) and (max-width: 1439px) {
      width: calc(100% - 192px);
      margin: 0 96px;
      padding: 93.5px 0;
    }
    @media screen and (min-width: 905px) and (max-width: 1239px) {
      width: calc(100% - 112px);
      margin: 0 56px;
      padding: 56px 0;
    }
    @media screen and (min-width: 600px) and (max-width: 904px) {
      width: calc(100% - 64px);
      margin: 0 32px;
      padding: 56px 0;
    }
    @media screen and (max-width: 599px) {
      width: calc(100% - 32px);
      margin: 0 16px;
      padding: 56px 0;
    }
    .career-txt {
      color: #fff;
    }
  }
`;
