import React, { JSXElementConstructor } from 'react';
export type DropdownChangeEvent = MouseEvent | KeyboardEvent;
export type OnChangeType = (e: DropdownChangeEvent, value: string) => void;
export type ToggleContextValue = {
  isOpen: boolean;
  toggle: () => void;
  disabled: boolean;
  onChange: OnChangeType;
  container: null | HTMLDivElement;
  toggleRef: null | React.RefObject<HTMLButtonElement>;
  optionListRef: null | React.RefObject<HTMLUListElement>;
  changeStyle: boolean;
  displayBorder: boolean;
};

export type DropdownProps = {
  changeStyle: boolean;
  disabled: boolean;
  onChange: OnChangeType;
  displayBorder?: boolean;
  isPositionFixed: boolean;
};

export type ToggleProps = {} & React.HTMLAttributes<HTMLButtonElement>;

export type OptionListProps = {} & React.HTMLAttributes<HTMLUListElement>;

export type OptionItemProps = {
  value: string;
  label: string;
  selected: boolean;
  disabled: boolean;
} & React.HTMLAttributes<HTMLLIElement>;
