import { NewLinkedInIcon, NewNotionIcon } from 'assets/icons';
import { SNSText } from 'global-styles';
import React from 'react';
import styled from 'styled-components';

const SnsComponent = () => {
  return (
    <SnsComponentContainer>
      <SNSText className="sns-t">FOLLOW US ON SOCIAL MEDIA</SNSText>
      <div className="icon-wrapper">
        <a
          href="https://www.linkedin.com/company/bluedot-io"
          target="_blank"
          rel="noreferrer"
          className="sns-link"
        >
          <NewLinkedInIcon />
        </a>
        <a
          href="https://www.notion.so/blue-dot/DeepField-SR-AI-based-Super-Resolution-Accelerated-by-FPGA-af4d1ab39f374db3a927c0cbd10a96c0"
          target="_blank"
          rel="noreferrer"
          className="sns-link"
        >
          <NewNotionIcon />
        </a>
      </div>
    </SnsComponentContainer>
  );
};

export default SnsComponent;

const SnsComponentContainer = styled.div`
  .sns-t {
    padding: 8px;
  }
  .icon-wrapper {
    margin-top: 15px;
    padding-left: 8px;
  }
  .sns-link + .sns-link {
    margin-left: 8px;
  }
`;
