import { useAppContext } from 'context/AppContext';
import { usePrivatePolicyContext } from 'context/PrivatePolicyContext';
import { useCallback } from 'react';

export const useContactModal = () => {
  const {
    state: { isContactModalOpen },
    dispatch,
  } = useAppContext();
  const handleOpenContactModal = useCallback(
    () => dispatch({ type: 'open' }),
    [dispatch]
  );
  const handleCloseContactModal = useCallback(
    () => dispatch({ type: 'close' }),
    [dispatch]
  );

  return {
    isContactModalOpen,
    handleOpenContactModal,
    handleCloseContactModal,
  };
};

export const usePrivatePolicyModal = () => {
  const {
    state: { isPrivatePolicyModalOpen },
    dispatch,
  } = usePrivatePolicyContext();
  const handleOpenPrivatePolicyModal = useCallback(
    () => dispatch({ type: 'open' }),
    [dispatch]
  );
  const handleClosePrivatePolicyModal = useCallback(
    () => dispatch({ type: 'close' }),
    [dispatch]
  );

  return {
    isPrivatePolicyModalOpen,
    handleOpenPrivatePolicyModal,
    handleClosePrivatePolicyModal,
  };
};
