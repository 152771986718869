import { GRAY30 } from 'global-styles';
import { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

interface CustomizedCheckboxProps {
  /** label로 사용될 컴포넌트 입니다. */
  labelComponent: ReactNode;
  /** 체크박스의 id 값을 지정합니다. */
  htmlId: string;
  isChecked: boolean;
  /** 체크박스의 disable 상태를 지정합니다. */
  disabled: boolean;
  /** input checkbox의 name을 지정합니다. */
  name?: string;
  /** 체크마크의 top 위치를 지정합니다. */
  top?: string;
  /** 체크마크의 left 위치를 지정합니다. */
  left?: string;
  /** ClassName을 지정합니다. */
  className?: string;
  onClick: (...args: any[]) => any;
  onKeyDown: (...args: any[]) => any;
}

export const CustomizedCheckbox = ({
  labelComponent,
  className,
  isChecked,
  onClick,
  htmlId,
  disabled,
  onKeyDown,
  name,
  top,
  left,
}: CustomizedCheckboxProps) => {
  return (
    <CustomizedCheckboxContainer
      className={className}
      top={top}
      left={left}
      disabled={disabled}
    >
      <input
        aria-checked={isChecked}
        type="checkbox"
        name={name}
        id={htmlId}
        checked={isChecked}
        onKeyDown={e => {
          e.stopPropagation();
          if (e.code === 'Enter' || e.code === 'Space') onKeyDown();
        }}
        className="a11y-hidden"
        disabled={disabled}
        aria-disabled={disabled}
        readOnly
      />
      <label
        onClick={onClick}
        onKeyDown={onKeyDown}
        htmlFor={htmlId}
        style={{ color: disabled ? '#bec6cf' : 'unset' }}
      >
        {labelComponent}
      </label>
    </CustomizedCheckboxContainer>
  );
};

const spinUp = keyframes`
0% {
  opacity:0;
  transform:  rotate(480deg);
}
100% {
  
  opacity:1;
  transform:  rotate(0);
}
`;

interface CustomizedCheckboxContainerProps {
  disabled: boolean;
  top?: string;
  left?: string;
}

const CustomizedCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${GRAY30};
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 500;
  label {
    position: relative;
    flex-shrink: 0;
    cursor: ${({ disabled }: CustomizedCheckboxContainerProps) =>
      disabled ? 'not-allowed' : 'pointer'};
  }

  input[type='checkbox'] {
    position: absolute;
  }
  input[type='checkbox'] + label {
    position: relative;
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] + label::before {
    content: '';
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 2px solid #525f6f;
    border-radius: 50%;
    transition: 0.15s all ease-in-out;
    flex-shrink: 0;
  }

  input[type='checkbox']:checked + label::before {
    background: #00438e;
    border: 2px solid #00438e;
    color: #fff;
  }

  input[type='checkbox']:focus-visible + label::before {
    outline: #0e80ff solid 2px;
    box-shadow: 0 0px 8px #0e80ff;
  }

  input[type='checkbox']:focus-visible + label::after {
    top: ${({ top }: CustomizedCheckboxContainerProps) => top || '11px'};
    left: ${({ left }: CustomizedCheckboxContainerProps) => left || '12px'};
  }

  input[type='checkbox']:active + label::before {
    outline: none;
    box-shadow: none;
  }

  input[type='checkbox']:disabled + label::before {
    background: transparent;
    border: 2px solid #bec6cf;
  }

  input[type='checkbox']:checked + label::after {
    content: '';
    animation: ${spinUp} 0.2s ease-in-out;
    position: absolute;
    top: ${({ top }: CustomizedCheckboxContainerProps) => top || '6px'};
    left: ${({ left }: CustomizedCheckboxContainerProps) => left || '4px'};
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    width: 11px;
    height: 6px;
    transform: rotate(-45deg);
  }
`;
